import { send_info } from './lib/libajax';
import { update_browser_state } from './lib/libstorage';

export function register(postData, cmpnt, callback){
	send_info('/user/',JSON.stringify(postData),
	function(data){
	  update_browser_state(cmpnt.props.parentComponent.state.app_id,'install-state','registered');
	  update_browser_state(cmpnt.props.parentComponent.state.app_id,'consumer-user',data['User']);
	  cmpnt.props.parentComponent.setState({
		install_state:'registered',
		registration_output:data['message'],
	  });
	  if(callback){
		  callback();
	  }
	  send_info(
		'/user/'+data.User.user_id+'/activity/'+cmpnt.props.parentComponent.state.customer_user_id,
		JSON.stringify({
		  activity_type:'install',
		  activity_time:(new Date()).toUTCString(),
		  user_id:cmpnt.props.parentComponent.state.customer_user_id,
		  page_user_id:data.User.user_id
		}),
		function(data){
			window.location.pathname='/';
		}
		);
	  
	  
	},
	function(res){
	  console.log(res);
	},
	null,
	'POST'
  );
};

export function appendQueryParam(queryParam, value){
	value=encodeURIComponent(value);
	let currentUrl = window.location.href;
	let currentParams = currentUrl.split('?');
	if(currentParams.length<=1){
		currentUrl+='?'+queryParam+'='+value;
	}else{
		currentUrl+='&'+queryParam+'='+value;
	}
	window.history.pushState({ path: window.location.href }, '', currentUrl);
}
