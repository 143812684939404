import config from '../config';

export function get_backend_host(){
  return config.backend_host;
  
//  return window.location.protocol+'//'+window.location.hostname+':9094'; //the same machine as what's hosting the front-end
//  return 'https://my1card.rhinoactive.com'; //production
}

export function strip_backend_host(str,path=''){
  if(str.indexOf(get_backend_host()+path)===0){
    return str.substring((get_backend_host()+path).length+1);
  }
  return str;
}

//get generic information from the tornado back-end
//this requires CORS to be configured on the back-end server
export function fetch_info(path,have_data_callback,error_callback=function(response){},abort_signal=null,no_data_callback=function(response){}){
  let fetch_init={
    method:'GET'
  };
  if(abort_signal!==null){
    fetch_init.signal=abort_signal;
  }
  return fetch(
    get_backend_host()+path,
    fetch_init
  ).then(
    function(response) {
      if(response.status === 204) {
        console.log("(info) fetch_info got 204 - no content and is not calling the have_data_callback function");
        no_data_callback(response);
      }else if(response.status === 200 || response.status === 304){
        return response.json().then(data => {
          return have_data_callback(data);
        });
      }else{
        error_callback(response);
      }

    },
    error_callback
  );
}

//send generic information to the tornado back-end
//this is done as an AJAX POST request by default
export function send_info(path,body_data,have_data_callback,error_callback=function(response){},abort_signal=null,method='POST',no_data_callback=function(response){}){
  let fetch_init= {
    method:method,
//    headers: {'Content-Type':'application/json'}, //this causes the method to be OPTIONS because react is stupid
    body: body_data,
  };
  if(abort_signal!==null){
    fetch_init.signal=abort_signal;
  }
  return fetch(
    get_backend_host()+path,
    fetch_init
  ).then(
    function(response) {
      if(response.status === 204){
        console.log("(info) send_info got 204 - no content and is not calling the have_data_callback function");
        no_data_callback(response);
      }else if(response.status === 200 || response.status === 304){
        return response.json().then(data => {
          return have_data_callback(data);
        });
      }else{
        error_callback(response);
      }
    },
    error_callback
  );
}

