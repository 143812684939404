import React, { Component } from 'react';
//import {fetch_info} from '../lib/libajax.jsx';

class ListMenu extends Component {
  constructor(props) {
    super();

    this.state={
      list_slug:'home-page-menu',
      menu_items:[]
    };
  }
  
  componentDidMount(){
    //needed in order to cancel network requests on unmount
    if(document.abort_controller!==undefined){
      this.abort_controller = new document.abort_controller();
      this.abort_signal = this.abort_controller.signal;
    }
  }

  componentWillUnmount() {
    //stop AJAX requests
    if(document.abort_controller!==undefined){
      this.abort_controller.abort();
    }

    //do any other necessary cleanup here
  }
  
  render() {
    let list_slug=this.list_slug;
    
    let icon_cell_classes='small-2';
    if(this.props.icon_cell_classes!==undefined){
      icon_cell_classes=this.props.icon_cell_classes;
    }
    
    let title_cell_classes='small-10';
    if(this.props.title_cell_classes!==undefined){
      title_cell_classes=this.props.title_cell_classes;
    }

    let empty_msg='No items exist.';
    if(this.props.empty_msg!==undefined){
      empty_msg=this.props.empty_msg;
    }
    
    let link_target='_self';
    if(this.props.link_target!==undefined){
      link_target=this.props.link_target;
    }
    
    return (
      <div className={(this.props.css_classes!==undefined)?'list-menu '+this.props.css_classes:'list-menu'}>
        <div className='grid-container'>
          {this.props.menu_items.length>0?
            this.props.menu_items.map(function(menu_item,idx){
              return (
                <a className='menu-entry hover-bg'
                  href={typeof(menu_item.href)==='string'?menu_item.href:''}
                  onClick={typeof(menu_item.href)==='function'?menu_item.href:null}
                  target={(menu_item.target!==undefined)?menu_item.target:link_target}
                  key={list_slug+'-item-'+idx}
                >
                  <div className='grid-x grid-padding-x'>
                    <div className={icon_cell_classes+' cell icon-cell'}>
                      {(menu_item.portrait!==undefined)?
                        <div className='has-bg menu-portrait' style={{backgroundImage:'url("'+menu_item.portrait+'")'}} title='Portrait' />
                      :
                        ((menu_item.icon!==null)?
                          ((menu_item.icon.startsWith('http://') || (menu_item.icon.startsWith('https://')))?
                            <div className='has-bg list-menu-icon' style={{backgroundImage:'url("'+menu_item.icon+'")'}} title='Icon' />
                          :
                            <i className={'fa fa-'+menu_item.icon} />
                          )
                        :
                          <i className={'fa fa-star'} />
                        )
                      }
                    </div>
                    <div className={title_cell_classes+' cell title-cell'}>
                      <div>
                        <h2 className='menu-entry-title'>{menu_item.title}</h2>
                        {(menu_item.subtitle!==undefined)?
                          <p className='menu-entry-subtitle'>{menu_item.subtitle}</p>:''
                        }
                      </div>
                    </div>
                  </div>
                </a>
              );
            })
            :
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell page-content'>
                <p>{empty_msg}</p>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default ListMenu;

