export function get_browser_state(customer_id){
  //get browser localstorage state and parse it
  var browser_state=localStorage.getItem('my1card-state');
  if(browser_state!==null){
    browser_state=JSON.parse(browser_state);
  }else{
    return null;
  }
  
  //check for the requested customer, return data if we have it
  if(browser_state[customer_id]!==undefined){
    return browser_state[customer_id];
  }
  //requested customer not found, return null
  return null;
}

export function get_browser_state_field(customer_id,field){
  var browser_state=get_browser_state(customer_id);
  if(browser_state===null){
    return null;
  }
  if(browser_state[field]!==undefined){
    return browser_state[field];
  }
  return null;
}

export function update_browser_state(customer_id,field,value){
  //get existing browser localstorage and parse it
  var browser_state=localStorage.getItem('my1card-state');
  if(browser_state!==null){
    browser_state=JSON.parse(browser_state);
  }else{
    //if there was none, then make it now!
    browser_state={};
  }
  
  //if the customer wasn't in the local storage then add it now
  if(browser_state[customer_id]===undefined){
    browser_state[customer_id]={};
  }
  
  //set the field to the value given
  browser_state[customer_id][field]=value;
  
  //save the update in the browser storage
  localStorage.setItem('my1card-state',JSON.stringify(browser_state));
}

export function omit_browser_state_field(customer_id,field){
  //get existing browser localstorage and parse it
  var browser_state=localStorage.getItem('my1card-state');
  if(browser_state!==null){
    browser_state=JSON.parse(browser_state);
  }else{
    //if there was none, then there's nothing to do
    return;
  }
  
  //if the customer wasn't in the local storage then there's nothing to do
  if(browser_state[customer_id]===undefined){
    return;
  }
  
  //remove the field from the object entirely
  delete browser_state[customer_id][field];
  
  //save the update in the browser storage
  localStorage.setItem('my1card-state',JSON.stringify(browser_state));
}

