import React from 'react';
import {send_info} from '../lib/libajax.jsx';
import {reformat_phone} from '../lib/libformat.jsx';
import {get_browser_state_field} from '../lib/libstorage.jsx';


class UpdateInformation extends React.Component {
  constructor(props) {
    super();
    
    this.state={
    };
  }

  share_form_submit(cmpnt,e) {
    e.preventDefault();

    let user_name=document.getElementById('user-name').value;
    let user_phone=document.getElementById('user-phone').value;
    let user_email=document.getElementById('user-email').value;
    if(user_email===''){
      user_email=null;
    }

    cmpnt.props.parentComponent.set_consumer_user_info(
      user_name,
      user_phone,
      user_email
    );
    
    if(document.getElementById('set-default').checked){
      let consumer_user_info=get_browser_state_field(cmpnt.props.parentComponent.state.app_id,'consumer-user');
      if(consumer_user_info!==null){
        //if the user has an entry in the user table, update that
        send_info('/user/'+consumer_user_info.user_id,JSON.stringify({
          full_name:user_name,
          cell_phone:user_phone,
          email:user_email
        }),
        function(data){
          console.log(data);
        },
        function(response){},
        null,
        'PUT');
      }else{
        //otherwise just update the referral table which is what is currently storing the user's information
        send_info('/referral/',JSON.stringify({
          code:cmpnt.props.parentComponent.state.referral_code,
          name:user_name,
          phone:user_phone,
          email:user_email
        }),
        function(data) {
          console.log(data);
        },
        function(response){},
        null,
        'PUT');
      }
    }
    
    cmpnt.props.parentComponent.toggle_modal('update-info');
    
    return false;
  }

  render () {
    return (
      <div className='modal left-align update-info-display'>
        <div className='grid-container'>
          <div className='modal-close-cont'>
            <a className='modal-close'
              onClick={function (cmpnt) {
                return function(e) {
                  cmpnt.props.parentComponent.toggle_modal('update-info');
                }
              }(this)}
            >
              &times;
            </a>
          </div>
        </div>
        <div className='grid-container'>
          <div className='grid-x grid-padding-x'>
            <div className='small-12 cell'>
              <h2 className='modal-title'>Update Information</h2>
            </div>
          </div>
          <form method='post' onSubmit={function(cmpnt) {
            return function (e) {
              return cmpnt.share_form_submit(cmpnt,e);
            };
          }(this)}>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell'>
                <label htmlFor='user-name'>Name</label>
                <div className='input-cont' key={'user-name-'+this.props.consumer_user_info.full_name}>
                  <input type='text' id='user-name' name='user-name' required
                    placeholder='...' defaultValue={this.props.consumer_user_info.full_name}
                  />
                </div>
              </div>
              <div className='small-12 cell'>
                <label htmlFor='user-phone'>Contact Phone</label>
                <div className='input-cont' key={'user-phone-'+this.props.consumer_user_info.cell_phone}>
                  <input type='text' id='user-phone' name='user-phone' pattern='(\([0-9]{3}\) [0-9]{3}-[0-9]{4})|([0-9]{10})' required
                    placeholder='(___) ___-____' defaultValue={reformat_phone(this.props.consumer_user_info.cell_phone)}
                    onKeyUp={function(e){
                      e.target.value=reformat_phone(e.target.value,true);
                    }}
                  />
                </div>
              </div>
              <div className='small-12 cell'>
                <label htmlFor='user-email'>Contact Email</label>
                <div className='input-cont' key={'user-email-'+this.props.consumer_user_info.email}>
                  <input type='email' id='user-email' name='user-email'
                    placeholder='...' defaultValue={this.props.consumer_user_info.email}
                  />
                </div>
              </div>
              <div className='small-12 cell'>
                <div className='input-cont x-checkbox'>
                  <input type='checkbox' name='set-default' id='set-default' />
                  <label className='x-checkbox-label' htmlFor='set-default'><div className='checkbox-icon' />Use this as my default</label>
                </div>
              </div>
{/*
              <div className='small-12 cell'>
                <label htmlFor='set-default'>Use this as my default?</label>
                <div className='input-cont yes-no-checkbox'>
                  <input type='checkbox' name='set-default' id='set-default' />
                  <label htmlFor='set-default' className='yn-option yes'>
                    <div className='checkbox-icon circle' />
                    <span className='text-label'>Yes</span>
                  </label>
                  <label htmlFor='set-default' className='yn-option no'>
                    <div className='checkbox-icon circle' />
                    <span className='text-label'>No</span>
                  </label>
                </div>
              </div>
*/}
              <div className='small-12 cell'>
                <input type='submit' value='Update' />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default UpdateInformation;
