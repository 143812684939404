import React from 'react';

class ReferralCodeFailure extends React.Component {
  constructor(props) {
    super();

    this.state={
    };
  }

  render() {
    return (
      <div className='referral-code-failure-page generic-page'>
        <div className='page-title-cont'>
          <div className='grid-container'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell page-title-cell'>
                <h1 className='page-title'>Invalid Referral Code</h1>
              </div>
            </div>
          </div>
        </div>
        <div className='grid-container'>
          <div className='grid-x grid-padding-x'>
            <div className='small-12 cell page-content'>
              <p>This error is usually caused by a mistyped or miscopied URL.  Please check the URL you were provided and ensure you are entering it exactly and completely.  </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReferralCodeFailure;

