
//remove the get data from a url
export function url_without_get_data(url){
    var get_start_idx=url.indexOf('?');
    //if there is no get data at all then return the original url
    if(get_start_idx<0){
        return url;
    }
    return url.substring(0,get_start_idx);
}

//put all the get data from the url into a javascript object
export function parse_get_data(url){
    var get_data={};
    var get_start_idx=url.indexOf('?');
    //if there is no get data at all then return an empty object
    if(get_start_idx<0){
        return get_data;
    }
    url=url.substring(get_start_idx+1);
    //while there is get data
    var eq_idx=url.indexOf('=');
    while(eq_idx>=0){
        //determine the name and value of the get data based on the pre-defined delimeters
        var name=url.substring(0,eq_idx);
        var end_idx=url.indexOf('&');
        if(end_idx<0){
            end_idx=url.length;
        }
        
        //strip off any trailing hash data, as that's not part of the GET data,
        //but rather is a separate thing sometimes found at the end of urls
        var value=url.substring(eq_idx+1,end_idx).split('#')[0];
        
        //save it
        get_data[name]=value;
        
        //look for more data
        url=url.substring(end_idx+1);
        eq_idx=url.indexOf('=');
    }
    //we're now out of data, so return what we found
    return get_data;
}

//store a javascript object as get data for a url
export function store_get_data(js_obj){
    var get_string='?';
    var keys=Object.keys(js_obj);
    for(var i=0;i<keys.length;i++){
        if(i>0){
            get_string+='&';
        }
        get_string+=keys[i]+'='+js_obj[keys[i]];
    }
    return get_string;
}


