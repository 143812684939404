import React from 'react';

import { send_info } from '../lib/libajax.jsx';
import { update_browser_state } from '../lib/libstorage.jsx';
import { parse_get_data } from '../lib/libgetdata.jsx';

class LoginPage extends React.Component {
    constructor() {
        super()
        this.state = {
        }
    }

    static outputError(message) {
        document.getElementById('login-error-output').innerText = message;
    }

    static submitLoginEvent(cmpnt) {
        return function (e) {
            let email = document.getElementById('email').value;
            let password = document.getElementById('password').value;
            send_info('/login', JSON.stringify({
                'email': email,
                'password': password,
            }),
            function (data) {
                LoginPage.outputError('');
                console.log(data)
                if ('Session' in data) {
                    if (data['Session'] !== null) {
                        update_browser_state(cmpnt.props.parentComponent.state.app_id,'login-session',data['Session']['session_id']);
                        cmpnt.props.parentComponent.setState({authorization:data['Session']['session_id']});
                        
                        let refcode_suffix=(cmpnt.props.parentComponent.state.referral_code!==null)?'?code='+cmpnt.props.parentComponent.state.referral_code:'';
                        let get_data=parse_get_data(document.location.href);
                        if('app_id' in get_data){
                            if(refcode_suffix===''){
                                refcode_suffix='?app_id='+get_data['app_id'];
                            }else{
                                refcode_suffix+='&app_id='+get_data['app_id'];
                            }
                        }
                        
                        window.location.href='/'+refcode_suffix;
                    } else {
                        LoginPage.outputError('An unknown error occurred');
                    }
                } else if ('success' in data) {
                    LoginPage.outputError(data['message']);
                } else {
                    LoginPage.outputError('An unknown error occurred');
                }
            },
            function(error){
              console.log(error);
              error.json().then(data => {
                if('message' in data){
                  LoginPage.outputError(data['message']);
                }else{
                  LoginPage.outputError('An unknown error occurred');
                }
              });
            }
            );

            e.preventDefault();
            return false;
        };
    }

    formWrap(content, id) {
        return (
            <div className='login-card'>
                <div className='site-logo-cont'>
                    <img className='site-logo' alt='' src='/assets/images/Logo-Large.png' />
                </div>
                <form className='login-form'
                    id={id}
                    onSubmit={LoginPage.submitLoginEvent(this)}>
                    {content}
                </form>
                <div id='login-error-output' className='form-error-output login-error-output'></div>
            </div>
        )
    }

    loginCard() {
        return (
            <div>
                <label htmlFor='email'>Email</label>
                <input id='email' name='email' type='email' placeholder='...' required />
                <label htmlFor='password'> Password </label>
                <input id='password' name='password' type='password' placeholder='*****' required />
                <div className='button-cont'>
                    <input type='submit' value='Login' />
                </div>

            </div>
        )
    }

    render() {
        let card=this.loginCard();
        let formId="login-form";
        return (
            <div className='login-page'>
                <div className='grid-container'>
                    <div className='grid-x grid-padding-x'>
                        <div className='small-12 cell login-cell'>
                            {this.formWrap(card, formId)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginPage;

