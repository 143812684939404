import React from 'react';
import {send_info} from '../lib/libajax.jsx';
import {reformat_phone} from '../lib/libformat.jsx';
import {update_browser_state} from '../lib/libstorage.jsx';

class RegisterPage extends React.Component {
  constructor(props) {
    super();

    this.state={
    };
  }

  register_form_submit(cmpnt,e){
    e.preventDefault();
    
    let full_name=document.getElementById('full-name').value;
    let cell_phone=document.getElementById('cell-phone').value;
    let email=document.getElementById('email').value;
    
    full_name=(full_name==='')?null:full_name;
    cell_phone=(cell_phone==='')?null:cell_phone;
    email=(email==='')?null:email;

    let post_data={
      full_name:full_name,
      phone:cell_phone,
      email:email,
    };
    if(this.props.customer_user_info!==null){
      post_data['customer_id']=this.props.customer_user_info.user_id;
    }
    
    let referral_code_id=cmpnt.props.parentComponent.state.referral_code_id;
    if(referral_code_id>=0){
      post_data.referral_code=referral_code_id;
    }
    
    //NOTE: by sending the referral_code in this request,
    //we link the new user account to the given referral code
//    send_info('/register/',JSON.stringify(post_data),
    send_info('/user/',JSON.stringify(post_data),
      function(data){
        var output_elem=document.getElementById('register-response-output');
        output_elem.className='response-output success';
        output_elem.innerText=data['message'];
        update_browser_state(cmpnt.props.parentComponent.state.app_id,'install-state','registered');
        update_browser_state(cmpnt.props.parentComponent.state.app_id,'consumer-user',data['User']);
        window.scrollTo(0,output_elem.offsetTop);
        cmpnt.props.parentComponent.setState({
          install_state:'registered',
          registration_output:data['message'],
        });
        send_info(
          '/user/'+data.User.user_id+'/activity/'+cmpnt.props.parentComponent.state.customer_user_id,
          JSON.stringify({
            activity_type:'install',
            activity_time:(new Date()).toUTCString(),
            user_id:cmpnt.props.parentComponent.state.customer_user_id,
            page_user_id:data.User.user_id
          }),
          function(register_data){ window.location.pathname='/';}
          );
        
       
      },
      function(response){
        var output_elem=document.getElementById('register-response-output');
        output_elem.className='response-output error';
        response.json().then(function(data){
          output_elem.innerText=data['message'];
          window.scrollTo(0,output_elem.offsetTop);
        });
      },
      null,
      'POST'
    );
    
    return false;
  }

  render() {
    return (
      <div className='register-page'>
        <div className='page-title-cont'>
          <div className='grid-container title-grid-cont'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell page-title-cell'>
                {((this.props.customer_user_info!==null) && (this.props.customer_user_info.profile_photo_url!==undefined))?
                  <div className='has-bg title-portrait' style={{backgroundImage:'url("'+this.props.customer_user_info.profile_photo_url+'")'}} title='Register' />:
                  <h1 className='page-title'>Register</h1>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='register-page-body-cont'>
          <div className='grid-container'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell'>
                <p className='register-title'>
                  Please register your app!
                </p>
                <p className='register-title'>
                  By registering you can expect personalized service. Your information is safe and only I will see it.
                </p>
              </div>
            </div>
          </div>
          <div className='grid-container'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell'>
                <div className='register-block'>
                  <form method='post' onSubmit={function(cmpnt) {
                    return function (e) {
                      return cmpnt.register_form_submit(cmpnt,e);
                    };
                  }(this)}>
                    <div className='grid-x grid-padding-x'>
                      <div className='small-12 cell'>
                        <label htmlFor='full-name'>Your full name*</label>
                        <div className='input-cont'>
                          <input type='text' id='full-name' name='full-name' required
                            placeholder='...' defaultValue={this.props.consumer_user_info.name}
                          />
                        </div>
                      </div>
                      <div className='small-12 cell'>
                        <label htmlFor='cell-phone'>What is your mobile number?*</label>
                        <div className='input-cont'>
                          <input type='text' id='cell-phone' name='cell-phone' required pattern='(\([0-9]{3}\) [0-9]{3}-[0-9]{4})|([0-9]{10})'
                            placeholder='(___) ___-____' defaultValue={reformat_phone(this.props.consumer_user_info.cell_phone,false)}
                            onKeyUp={function(e){
                              e.target.value=reformat_phone(e.target.value,true);
                            }}
                          />
                        </div>
                      </div>
                      <div className='small-12 cell'>
                        <label htmlFor='email'>What is your email? (optional)</label>
                        <div className='input-cont'>
                          <input type='email' id='email' name='email'
                            placeholder='...' defaultValue={this.props.consumer_user_info.email}
                          />
                        </div>
                      </div>
                      <div className='small-12 cell'>
                        <input type='submit' value='Register' />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className='small-12 cell'>
                <div className={'response-output '+(this.props.parentComponent.state.install_state==='registered'?'success':'')}  id='register-response-output'>{this.props.registration_output}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisterPage;

