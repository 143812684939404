import React from 'react';
import { send_info } from '../lib/libajax.jsx';
class NewsPage extends React.Component {
  constructor(props) {
    super();

    this.state = {
      page_title: 'News',
      news_items: [],
    };
  }

  componentDidMount() {
    //log every time the user opens the app by sending a request to the server
    if (this.props.customer_user_info.user_id >= 0 && this.props.consumer_user_info.user_id >= 0) {
      send_info(
        '/user/' + this.props.consumer_user_info.user_id + '/activity/' + this.props.customer_user_info.user_id,
        JSON.stringify({
          activity_type: 'news',
          activity_time: (new Date()).toUTCString(),
          user_id: this.props.customer_user_info.user_id,
          page_user_id: this.props.consumer_user_info.user_id
        }),
        function (data) { }
      );
    }
  }

  render() {
    console.log(this.props);

    let news_items = this.state.news_items;
    if ((this.props.customer_user_info !== undefined) && (this.props.customer_user_info.news !== undefined) && (this.props.customer_user_info.news !== null)) {
      news_items = this.props.customer_user_info.news;
    }

    return (
      <section className='news'>
        <div className='page-title-cont'>
          <div className='grid-container'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell page-title-cell'>
                <h1 className='page-title'>{this.state.page_title}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className='grid-container news-grid-container'>
          <div className='grid-x'>
            {news_items.length > 0 ?
              news_items.map(function (news_item, idx) {
                return (
                  <div className='small-12 cell news-item-cell' key={'news-item-' + idx}>
                    {news_item.url !== null ?
                      <a className='news-item-content news-item-link' href={news_item.url}>
                        <h2 className='news-title'>{news_item.title}</h2>
                        <p className='news-excerpt'>{news_item.excerpt}</p>
                      </a>
                      :
                      <div className='news-item-content'>
                        <h2 className='news-title'>{news_item.title}</h2>
                        <p className='news-excerpt'>{news_item.excerpt}</p>
                      </div>
                    }
                  </div>
                );
              })
              :
              <div className='small-12 cell page-content'>
                <p>No news, check back soon for updates!</p>
              </div>
            }
          </div>
        </div>
      </section>
    );
  }
}

export default NewsPage;

