import React from 'react';

import {send_info} from '../lib/libajax.jsx';
//TODO: Add confirmation popup before sending SMS
class SMSBroadcastPage extends React.Component {
  constructor(props) {
    super();

    this.state={
      broadcast_status:'not-started',
      broadcast_output:'',
    };
  }

  clear_broadcast_form(){
    document.getElementById('broadcast-field').value='';
    
    this.setState({broadcast_output:'',broadcast_status:'not-started'});
  }

  broadcast_form_submit(cmpnt,e) {
    e.preventDefault();
    
    let form_data={
        "message_text": document.getElementById('broadcast-field').value,
        "recipients": [3]
    };
    console.log(form_data)
    cmpnt.setState({broadcast_status:'pending',broadcast_output:''});
    
    //TODO: send this data to the server and display the result (success/failure)
    send_info('/user/'+this.props.consumer_user_info.user_id+'/message/?send_method=sms',JSON.stringify(form_data),
      function(data) {
        let broadcast_output='You have successfully broadcastd this app';
        cmpnt.setState({broadcast_status:'complete',broadcast_output:broadcast_output});
        //clear the form in case we want to broadcast to someone else after this
        cmpnt.clear_broadcast_form();
        
      },
      function(response){
        cmpnt.setState({broadcast_status:'failure',broadcast_output:'An error occurred and the app could not be broadcastd at this time'});
      },
      null,
      'POST',
      function(response){
        let broadcast_output='You have successfully broadcastd this app';
        cmpnt.setState({broadcast_status:'complete',broadcast_output:broadcast_output});
        //clear the form in case we want to broadcast to someone else after this
        cmpnt.clear_broadcast_form();
      });
    
    return false;
  }

  render() {
    return (
      <div className='contact-page'>
        <div className='page-title-cont'>
          <div className='grid-container'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell page-title-cell'>
                <h1 className='page-title'>SMS Broadcast</h1>
              </div>
            </div>
          </div>
        </div>
        <div className='grid-container'>
          <form method='post' onSubmit={function(cmpnt) {
            return function (e) {
              return cmpnt.broadcast_form_submit(cmpnt,e);
            };
          }(this)}>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell'>
                <label htmlFor='broadcast-content'>Content to Broadcast*</label>
                <div className='input-cont'>
                  <textarea type='text' id='broadcast-field' name='broadcast-field' rows="5" cols="20" required
                    placeholder='...'
                  />
                </div>
              </div>
              <div className='small-12 cell'>
                <input type='submit' value='Broadcast' />
              </div>
            </div>
          </form>
          <div className='grid-x grid-padding-x'>
            <div className='small-12 cell'>
              <div className={'response-output '+(this.state.broadcast_status==='complete'?'success':(this.state.broadcast_status==='failure'?'error':''))}  id='broadcast-response-output'>{this.state.broadcast_output}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SMSBroadcastPage;

