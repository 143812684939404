import React from 'react';

import {send_info} from '../lib/libajax.jsx';
import {reformat_phone} from '../lib/libformat.jsx';

class RequestShowing extends React.Component {
  constructor(props) {
    super();

    this.state={
      page_title:'Request a Showing',
      name:'User Name',
      phone:'555-555-5555',
      email:'---',
      realtor_name:props.customer_user_info.full_name,
      submission_state:'not-started',
      submission_output:'',
    };
  }

  request_showing_submit(cmpnt,e) {
    e.preventDefault();
    
    cmpnt.setState({
      submission_state:'pending',
      submission_output:'',
    });

    //get the user's data (that they may have just updated)
    let submission_data={
      name:cmpnt.props.consumer_user_info.full_name,
      phone:cmpnt.props.consumer_user_info.cell_phone,
      email:cmpnt.props.consumer_user_info.email,
    };
    
    //get all the custom form fields out of the custom form
    let form_elem=document.getElementById('custom-form');
    let input_fields=Array.from(form_elem.getElementsByTagName('INPUT'));
    input_fields=Array.concat(input_fields,Array.from(form_elem.getElementsByTagName('SELECT')));
    console.log(input_fields);
    for(let i=0;i<input_fields.length;i++){
      if((input_fields[i].id!==undefined) && (input_fields[i].id!=='')){
        submission_data[input_fields[i].id]=input_fields[i].value;
      }
    }

    //send this form submission to the server as json
    console.log(submission_data);
    
    send_info('/form-submit/'+this.props.customer_user_info.user_id,
      JSON.stringify(submission_data),
      function(data) {
        cmpnt.setState({
          submission_state:'complete',
          submission_output:'Success.  Your submission has been received.  ',
        });
        window.scrollTo(0,document.getElementById('form-response-output').offsetTop);
      },
      function(response){
        cmpnt.setState({
          submission_state:'failure',
          submission_output:'Error: your submission could not be processed at this time',
        });
        window.scrollTo(0,document.getElementById('form-response-output').offsetTop);
      },
      null,
      'POST'
    );
    
    return false;
  }

  render() {
    return (
      <div className='request-showing'>
        <div className='page-title-cont'>
          <div className='grid-container'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell page-title-cell'>
                <h1 className='page-title'>{this.state.page_title}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className='grid-container'>
          <section className='request-showing-section'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell'>
                <h3 className='section-title'>1. Review Your Information</h3>
              </div>
            </div>
            <div className='info-section'>
              <div className='label-cell'>
                Name:
              </div>
              <div className='content-cell'>
                {this.props.consumer_user_info.full_name}
              </div>
            </div>
            <div className='info-section'>
              <div className='label-cell'>
                Phone:
              </div>
              <div className='content-cell'>
                {reformat_phone(this.props.consumer_user_info.cell_phone)}
              </div>
            </div>
            <div className='info-section'>
              <div className='label-cell'>
                Email:
              </div>
              <div className='content-cell'>
                {this.props.consumer_user_info.email}
              </div>
            </div>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell'>
                <a className='button transparent update-info-button'
                  onClick={function (cmpnt) {
                    return function (e) {
                      cmpnt.props.parentComponent.toggle_modal('update-info');
                    };
                  }(this)}
                >
                  Update My Information
                </a>
              </div>
            </div>
          </section>
        </div>
        <div className='section-separator'></div>
        <div className='grid-container'>
          <section className='request-showing-section'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell'>
                <h3 className='section-title'>2. Fill Out Property Details</h3>
              </div>
            </div>
            <form id='custom-form' className='custom-form' method='post' onSubmit={function(cmpnt) {
              return function (e) {
                return cmpnt.request_showing_submit(cmpnt,e);
              };
            }(this)}>
              <div className='grid-x grid-padding-x'>
                <div className='small-12 cell'>
                  <label htmlFor='property-mls'>Property MLS #</label>
                  <div className='input-cont'>
                    <input type='text' id='property-mls' name='property-mls' placeholder='...' required />
                  </div>
                </div>
                <div className='small-12 cell'>
                  <label htmlFor='property-street-addr'>Property Street Address</label>
                  <div className='input-cont'>
                    <input type='text' id='property-street-addr' name='property-street-addr' placeholder='...' required />
                  </div>
                </div>
                <div className='small-12 cell'>
                  <label htmlFor='city'>City</label>
                  <div className='input-cont'>
                    <input type='text' id='city' name='city' placeholder='...' required />
                  </div>
                </div>
                <div className='small-12 cell'>
                  <label htmlFor='state-prov'>State/Province</label>
                  <div className='input-cont'>
                    <input type='text' id='state-prov' name='state-prov' placeholder='...' required />
                  </div>
                </div>
                <div className='small-12 cell'>
                  <label htmlFor='postal-code'>ZIP/Postal Code</label>
                  <div className='input-cont'>
                    <input type='text' id='postal-code' name='postal-code' placeholder='...' required />
                  </div>
                </div>
                <div className='small-12 cell'>
                  <label htmlFor='availability'>Best Available Time</label>
                  <div className='input-cont'>
                    <select id='availability' name='availability' required defaultValue='any' >
                      <option value='any'>Any Time</option>
                      <option value='bsns-hours'>Business Hours</option>
                      <option value='evenings'>Evenings</option>
                    </select>
                  </div>
                </div>
                <div className='small-12 cell'>
                  <label htmlFor='notes'>Notes for {this.state.realtor_name}</label>
                  <div className='input-cont'>
                    <input type='text' id='notes' name='notes' placeholder='...' />
                  </div>
                </div>
                <div className='small-12 cell'>
                  <input type='submit' value='Submit Request' />
                </div>
              </div>
            </form>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell'>
                <div className={'response-output '+(this.state.submission_state==='complete'?'success':(this.state.submission_state==='failure'?'error':''))}  id='form-response-output'>{this.state.submission_output}</div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default RequestShowing;

