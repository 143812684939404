import React, { Component } from 'react';
import { Collapse } from 'react-collapse';

import { parse_get_data } from '../lib/libgetdata.jsx';
import { get_browser_state_field, omit_browser_state_field } from '../lib/libstorage.jsx';

import ListMenu from '../component/ListMenu.jsx';

class ShortcutMenu extends Component {
  constructor(props) {
    super();
    let more_menu_toggle=function (cmpnt) {
      return function (e) {
        e.preventDefault();
        cmpnt.setState({more_menu_open:(!cmpnt.state.more_menu_open)});
        return false;
      };
    }(this);

    let modal_toggle=function (cmpnt,modal_id) {
      return function (e) {
        e.preventDefault();
        cmpnt.props.parentComponent.toggle_modal(modal_id);
        return false;
      }
    };
    let share_modal_toggle=modal_toggle(this,'share');


    let refcode_suffix=(props.parentComponent.state.referral_code!==null?('?code='+props.parentComponent.state.referral_code):'');
    let get_data=parse_get_data(document.location.href);
    if('app_id' in get_data){
      if(refcode_suffix===''){
        refcode_suffix='?app_id='+get_data['app_id'];
      }else{
        refcode_suffix+='&app_id='+get_data['app_id'];
      }
    }

    this.state={
      more_menu_open:false,
      refcode_suffix:refcode_suffix,
      menu_items:[
        {
          slug:'profile',
          title:'Profile',
          icon:'user',
          href:'/'+refcode_suffix,
        },
        {
          slug:'share',
          title:'Share',
          icon:'share-alt',
          href:share_modal_toggle,
        },
        {
          slug:'qrcode',
          title:'QR Code',
          icon:'qrcode',
          href:'/qr-code'+refcode_suffix,
        },
        {
          slug:'network',
          title:'Network',
          icon:'star',
          href:'/network'+refcode_suffix,
        },
        {
          slug:'more',
          title:'More',
          icon:'ellipsis-h',
          href:more_menu_toggle,
        },
      ],
      more_menu_items:[
/*
        {
          slug:'calculator',
          title:'Mortgage Calculator',
          icon:'calculator',
          href:'/calculator'+refcode_suffix,
        },
*/
/*
        {
          title:'Register',
          icon:'clipboard',
          href:'/register',
        },
*/
        // {
        //   title:'Privacy Policy',
        //   icon:'user-secret',
        //   href:'#',
        // },
      ]
    };
  }
  
  render() {
    
    let selected_slug=this.state.more_menu_open?'more':((this.props.parentComponent.state.open_modals.includes('share'))?'share':this.props.selected);
    let more_menu_items=this.state.more_menu_items;

    if(this.props.consumer_user_info!==null && this.props.customer_user_info!==null){
      if((this.props.consumer_user_info.user_id!==null) && (this.props.consumer_user_info.user_id===this.props.customer_user_info.user_id)){
        let authorization=get_browser_state_field(this.props.parentComponent.state.app_id,'login-session');
        
        //If the user is viewing their own app but they are not logged in
        //then show the login option
        if(authorization===null){
          more_menu_items=more_menu_items.concat([{
            title:'Login',
            icon:'user',
            href:'/login'+this.state.refcode_suffix,
          }]);
        //if the user is viewing their own app AND they are logged in
        }else{
          //then show additional menu items for logged in users
          
          //Add sms broadcast item to menu if logged in
          more_menu_items=more_menu_items.concat([
            // {
            //   title:'SMS broadcast',
            //   icon:'star',
            //   href:'/sms-broadcast'+this.state.refcode_suffix,
            // },
            //and show the logout button as well
            {
              title:'Logout',
              icon:'sign-out-alt',
              href:function (cmpnt) { return function(e) {
                e.preventDefault();
                omit_browser_state_field(cmpnt.props.parentComponent.state.app_id,'login-session');
                window.location.href='/'+cmpnt.state.refcode_suffix;
                return false;
              }; }(this),
            }
          ]);
        }
      }
    }
    return (
      <div className='shortcut-menu-cont'>
        <Collapse isOpened={this.state.more_menu_open}>
          <ListMenu menu_items={more_menu_items} list_slug='shortcut-menu-more' />
        </Collapse>
        <div className='grid-container shortcut-menu'>
          {this.state.menu_items.map(function(menu_item,idx){
            return (
              //the is-active css class is set based on the menu location, as defined by the parent class and passed in via props
              <a className={'menu-entry hover-bg'+(selected_slug===menu_item.slug?' is-active':'')}
                href={typeof(menu_item.href)==='string'?menu_item.href:''}
                onClick={typeof(menu_item.href)==='function'?menu_item.href:null}
                key={'shortcut-menu-entry-'+idx}
              >
                <i className={'fa fa-'+menu_item.icon} />
                <label className='menu-entry-title'>{menu_item.title}</label>
              </a>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ShortcutMenu;

