import React from 'react';
import QRCode from 'react-qr-code';

import { get_customer_app_url } from '../lib/libformat.jsx';

class QRCodePage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state={
    };
  }
  
  render() {
    let customer_user_info=this.props.customer_user_info;
    let customer_app_url=window.location.href;
    if(customer_user_info!==null){
      customer_app_url=get_customer_app_url(this.props.customer_user_info.app_id);
    }
    
    return (
      <div className='text-page'>
        <div className='page-title-cont'>
          <div className='grid-container'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell page-title-cell'>
                <h1 className='page-title'>QR Code</h1>
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          {/* generate a QR code for the customer app url here */}
          <QRCode value={customer_app_url} />
          <br />
          <br />
          {/* <a target='_blank' href={customer_app_url}>{customer_app_url}</a> */}
          <p>{customer_app_url}</p>
        </div>
      </div>
    );
  }
}

export default QRCodePage;

