import React from 'react';

import ListMenu from '../component/ListMenu.jsx';
import { send_info } from '../lib/libajax.jsx';
import { get_customer_app_url } from '../lib/libformat.jsx';

class Recommendations extends React.Component {
  constructor(props) {
    super();

    this.state={
    };
  }

  componentDidMount() {
    //log every time the user opens the app by sending a request to the server
    if (this.props.customer_user_info.user_id >= 0 && this.props.consumer_user_info.user_id >= 0) {
      send_info(
        '/user/' + this.props.consumer_user_info.user_id + '/activity/' + this.props.customer_user_info.user_id,
        JSON.stringify({
          activity_type: 'network',
          activity_time: (new Date()).toUTCString(),
          user_id: this.props.customer_user_info.user_id,
          page_user_id: this.props.consumer_user_info.user_id
        }),
        function (data) { }
      );
    }
  }
  
  render() {
    let users_menu_items=[];
    
    if(this.props.customer_user_info!==null){
      console.log(this.props.customer_user_info);
      users_menu_items=this.props.customer_user_info.recommendations.map(function (recommendation){
        let user=recommendation.User;
        return {
          title:user.full_name,
          icon:null,
          portrait:user.profile_photo_url,
          href:get_customer_app_url(user.app_id),
  //        subtitle:user.title+' at '+user.company,
          subtitle:user.title,
        }
      });
    }
    
    return (
      <div className='text-page'>
        <div className='page-title-cont'>
          <div className='grid-container'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell page-title-cell'>
                <h1 className='page-title'>Network</h1>
              </div>
            </div>
          </div>
        </div>
        <ListMenu menu_items={users_menu_items} css_classes='recommendations' icon_cell_classes='small-4 medium-3' title_cell_classes='small-8 medium-9' empty_msg='No recommendations, check back soon for updates!' link_target='_blank' />
      </div>
    );
  }
}

export default Recommendations;

