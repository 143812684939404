import React from 'react';

import ios_install_icon from '../assets/images/Screenshot_2018-11-02_10-06-18.png';
import { get_browser_state } from '../lib/libstorage';

class InstallInstructions extends React.Component {
  constructor(props) {
    super();

    let user_agent=navigator.userAgent;
    let platform='Android';
    if(user_agent.indexOf('iPhone')>=0){
      platform='iOS';
    }

    this.state={
      platform:platform,
      app_installed: false
    };
  }
  
  componentDidMount(){
    let visited = get_browser_state(this.props.app_id).visited;
    this.setState({app_installed:visited});
  }
  

  render() {
    //TODO: redirect to the "accept share" page in this case and then the "register" page prior to displaying the normal home/profile page
    let install_link=(
      <li>
        <p>
          <a
            onClick={function (cmpnt) {
              return function(event) {
                cmpnt.props.primaryComponent.setInstallState('installed');
                window.location.pathname='/';
              };
            }(this)}
          >
            Open the App
          </a>
        </p>
      </li>
    );
    let instructions=(
      <ol className='custom-numbers instructions'>
        <li>
          <p>
            Select menu or tap the <span className='fa fa-ellipsis-v'></span> icon <strong>in the upper right.</strong>
          </p>
        </li>
        <li>
          <p>
            Select “<strong>Add to Home Screen</strong>”.
          </p>
        </li>
        {!this.props.install_state && install_link}
      </ol>
    );
    if(this.state.platform==='iOS'){
      instructions=(
        <ol className='custom-numbers instructions'>
          <li>
            <p>
              Tap on the <img className='icon' src={ios_install_icon} alt='Install Icon' /> below.
            </p>
          </li>
          <li>
            <p>
              Select “<strong>Add to Home Screen</strong>”.
            </p>
          </li>
          {install_link}
        </ol>
      );
    }
    if(this.state.app_installed){
      return null;
    }
    return (
      <div className='install-instructions-page'>
        <div className='grid-container'>
          <div className='grid-x grid-padding-x'>
            <div className='small-12 cell'>
              <p className='install-instructions-title'>To install this app on your phone:</p>
            </div>
          </div>
        </div>
        <div className='grid-container'>
          <div className='grid-x grid-padding-x'>
            <div className='small-12 cell'>
              <div className='instruction-block'>
                {instructions}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InstallInstructions;

