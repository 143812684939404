import React from 'react';

import mgic_logo from '../assets/images/Screenshot_2018-10-02_13-34-46.png';

class MortgageCalculatorResult extends React.Component {
  constructor (props) {
    super();
    
    this.state={
      mgic_logo:mgic_logo,
    };
  }
  
  render () {
    let loan_term_years=this.props.calc_result.loan_term_years;
    let pretty_print_number=this.props.pretty_print_number;
    let loan_amount=this.props.calc_result.loan_amount;
    let monthly_payment=0;
    for(var i=0;i<this.props.calc_result.components.length;i++){
      monthly_payment+=this.props.calc_result.components[i].value/(loan_term_years*12);
    }
    
    return (
      <div className='modal calculator-result-display'>
        <div className='grid-container'>
          <div className='modal-close-cont'>
            <a className='modal-close'
              onClick={function (cmpnt) {
                return function(e) {
                  cmpnt.props.parentComponent.hide_result();
                }
              }(this)}
            >
              &times;
            </a>
          </div>
        </div>
        <div className='grid-container'>
          <div className='grid-x grid-padding-x'>
            <div className='small-12 cell'>
              <h2 className='modal-title'>Results</h2>
            </div>
          </div>
        </div>
        <div className='grid-container content-container'>
          <div className='chart-grid clearfix'>
            <div className='chart-cell'>
              <div className='breakdown-chart collapsed' id='mortgage-breakdown-chart'>
                {this.props.calc_result.components.map(function (component,idx) {
                  return (
                    <div className='breakdown-item'
                      style={{
                        backgroundColor:component.color,
                        height:(100*((component.value/(loan_term_years*12))/monthly_payment))+'%',
                      }}
                      key={'mortgage-breakdown-chart-item-'+idx}
                    >
                    </div>
                  );
                })}
              </div>
            </div>
            <div className='desc-cell'>
              <ul className='desc-list'>
                {this.props.calc_result.components.map(function (component,idx) {
                  return (
                    <li className='result-component' key={'result-component-'+idx}>
                      <div className='grid-x grid-padding-x'>
                        <div className='small-5 medium-7 cell'>
                          <h3 className='chart-component-name'>{component.name}</h3>
                        </div>
                        <div className='small-4 medium-4 cell'>
                          <div className='chart-value'>{pretty_print_number(component.value/(loan_term_years*12))}</div>
                        </div>
                        <div className='small-3 medium-1 cell chart-legend-cell'>
                          <div className='chart-legend-color' style={{backgroundColor:component.color}}></div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className='grid-container totals-container'>
          <div className='grid-x grid-padding-x totals-grid'>
            <div className='small-6 medium-8 cell'>
              <h3 className='total-label'>Your Monthly Payment:</h3>
            </div>
            <div className='small-6 medium-4 cell'>
              <div className='total-amount'>{pretty_print_number(monthly_payment)+'/mo'}</div>
            </div>
          </div>
          <div className='grid-x grid-padding-x totals-grid'>
            <div className='small-6 medium-8 cell'>
             <h3 className='total-label'>Total Loan Amount:</h3>
            </div>
            <div className='small-6 medium-4 cell'>
              <div className='total-amount'>{pretty_print_number(loan_amount)}</div>
            </div>
          </div>
        </div>
        <div className='grid-container'>
          <div className='grid-x grid-padding-x'>
            <div className='small-12 cell'>
              <p className='rate-provider-footnote'>
                MI rates provided by <img src={this.state.mgic_logo} alt='MGIC' />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MortgageCalculatorResult;

