import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import { Collapse } from 'react-collapse';
import tinycolor from 'tinycolor2';

import { fetch_info, send_info } from './lib/libajax.jsx';
import { parse_get_data, url_without_get_data } from './lib/libgetdata.jsx';
import { get_browser_state, update_browser_state, get_browser_state_field } from './lib/libstorage.jsx';

//import logo from './logo.svg';
import './App.css';
import './foundation.css';

import Modal from './component/Modal.jsx';
import ProfilePage from './component/ProfilePage.jsx';
import NewsPage from './component/NewsPage.jsx';
import RequestShowing from './component/RequestShowing.jsx';
import MortgageCalculator from './component/MortgageCalculator.jsx';
import Share from './component/Share.jsx';
import UpdateInformation from './component/UpdateInformation.jsx';
import ShortcutMenu from './component/ShortcutMenu.jsx';
import Recommendations from './component/Recommendations.jsx';
import RegisterPage from './component/RegisterPage.jsx';
import ReferralCodeFailure from './component/ReferralCodeFailure.jsx';
import ContactPage from './component/ContactPage.jsx';
import SocialPage from './component/SocialPage.jsx';
import QRCodePage from './component/QRCodePage.jsx';
import LoginPage from './component/LoginPage.jsx';
import SMSBroadcastPage from './component/SMSBroadcastPage.jsx';

import TextPage from './component/TextPage.jsx';
import CustomForm from './component/CustomForm.jsx';
import { appendQueryParam } from './helper-functions';

import header_bg from './assets/images/bg_header_angles_accent_universal@3x.png';
import ExpiredApp from './component/ExpiredApp.jsx';

//NOTE: throughout this app the "customer" is the person who pays a subscription and customizes this app
//by contrast, the "consumer" is the end user who might view and use this app

class App extends Component {
  constructor(props) {
    super();
    
    let get_data=parse_get_data(document.location.href);

    //initialize app_id from subdomain if possible
    let app_id=null;
    if(window.location.hostname!=='localhost'){
      let domain_parts=window.location.host.split('.');
      //lowest level domain is used for user identifier
      let lld=domain_parts[0];
      if((domain_parts.length)>1){
        app_id=lld;
      }
    }
    //if we can't initialize app id from subdomain then do it from get data
    if((app_id===null) && (get_data['app_id']!==undefined)){
      app_id=get_data['app_id'];
    }
    //if it's still null then no app id was given and we won't know what to load until we do a request to /referral/
    if(app_id===null){
      //so for the purposes of accessing browser state use an app id that will never be associated with a valid app
      app_id=' tmp '; //spaces are disallowed in app_id strings
    }

    let browser_state=get_browser_state(app_id);

    let referral_code=null;
    if(get_data['code']!==undefined){
      referral_code=get_data['code'];
      update_browser_state(app_id,'referral-code',get_data['code'])
//    }else if((browser_state!==null) && (browser_state['referral-code']!==undefined)){
//      referral_code=browser_state['referral-code'];
    }

    let consumer_user_info={
      full_name:'User Name',
      cell_phone:'555-555-5555',
      email:'',
      user_id:-1
    };
    let customer_user_info=null;
    
    let customer_user_id=-1;
    let consumer_user_id=-1;
    
    if((browser_state!==null) && (browser_state['consumer-user']!==undefined)){
      consumer_user_info=browser_state['consumer-user'];
      consumer_user_id=consumer_user_info.user_id;
    }

    let accent_color='';
    if((browser_state!==null) && (browser_state['customer-user']!==undefined)){
      customer_user_info=browser_state['customer-user'];
      customer_user_id=customer_user_info.user_id;
      
      if((customer_user_info.accent_color!==null) && (customer_user_info.accent_color!=='')){
        accent_color='#'+customer_user_info.accent_color;
      }
      //don't display customer data immediately
      //because we don't know what customer we're getting data for
      //until the referral request completes
      customer_user_info=null;
    }
    
    let history = createHistory();

    let modal_toggle=function (cmpnt,modal_id) {
      return function (e) {
        e.preventDefault();
        cmpnt.toggle_modal(modal_id);
        return false;
      }
    };
    let share_modal_toggle=modal_toggle(this,'share');

    let install_state='unknown';
    if((browser_state!==null) && (browser_state['install-state']!==undefined)){
      install_state=browser_state['install-state'];
    }
    
    this.state = {
      app_expired:false,
      history:history,
      selected_shortcut:'profile',
      open_modals:[],
      //when login is implemented, this will hold the user's session id
      authorization:'',

      referral_code:referral_code,
      referral_code_id:-1,
      
      //this is an "app" built for a "customer" (for example a real estate agent)
      //as each "customer" can configure their own "app" we have to define the customer's user_id to know which app content to display
      //per their customizations
      customer_user_id:customer_user_id,
      customer_user_info:customer_user_info,
      accent_color:accent_color,
      
      //the "consumer" is the end user who actually views this "app"
      consumer_user_id:consumer_user_id,
      consumer_user_info:consumer_user_info,

      //the menu items to show on the home page menu
      //we need to know them here for routing purposes
      home_menu_items:[
          {
            title:'Network',
            icon:'star',
            href:'/network',
            page_type:null,
          },
          {
            title:'Share App - Refer Me',
            icon:'share-alt',
            href:share_modal_toggle,
            page_type:null,
          },
          {
            title:'Get Goos',
            icon:'address-card',
            href:'https://goos.app',
            page_type:'link',
            target:'_blank',
          },
      ],

      share_modal_toggle:share_modal_toggle,

      install_state:install_state,
      registration_output:'',
      referral_code_output:'',
      share_output:'',
      app_id:app_id,
      load_title:'Loading...',
      load_text_content:'Please wait while we retrieve the relevant information.',
    };
  }

  setInstallState(new_state){
    let install_state=get_browser_state_field(this.state.app_id,'install-state');
    if(install_state!=='registered'){
      update_browser_state(this.state.app_id,'install-state',new_state);
    }else{
      new_state='registered';
    }

    this.setState({
      install_state:new_state
    });
  }

  fetchCustomerData(app_id=null) {
    let fetch_url='/customer/?customer_user_id='+this.state.customer_user_id+'&Authorization='+this.state.authorization+'&full=true';
    if(app_id!==null){
      fetch_url='/customer/?customer_app_id='+app_id+'&Authorization='+this.state.authorization+'&full=true';
    }
    fetch_info(fetch_url,
      function (cmpnt) {
        return function (data) {
          cmpnt.setState({app_expired: !data.User.is_subscription_active})
          let menu_items=[];
          data['Page']=data.User.page;
          let refcode_suffix=(cmpnt.state.referral_code!==null?('?code='+cmpnt.state.referral_code):'');
          let get_data=parse_get_data(document.location.href);
          if(refcode_suffix===''){
            if('app_id' in get_data){
              refcode_suffix='?app_id='+get_data['app_id'];
            }
          }

          //add generic home page menu items which are available to everyone
          //regardless of user page configuration
          menu_items.push({
            title:'Contact Me',
            icon:'address-book',
            href:'/contact-me'+refcode_suffix,
            page_type:null,
          });

          if(data.User.about_me!==null){
            menu_items.push({
              title:'About Me',
              icon:'user',
              href:'/about-me'+refcode_suffix,
              slug:'about-me',
              page_type:'text',
              page_content:{
                'name':'About Me',
                'text_content':data.User.about_me,
              }
            });
          }
          menu_items.push({
            title:'News',
            icon:'newspaper',
            href:'/news'+refcode_suffix,
            page_type:null,
          });

          menu_items.push({
            title:'Social Media',
            icon:'share',
            href:'/social'+refcode_suffix,
            page_type:null,
          });
          
          for(var i=0;i<data['Page'].length;i++){
            let page_type=data['Page'][i]['page_type'];
            if(page_type==='link'){
              //links just link to other urls
              menu_items.push({
                title:data['Page'][i]['name'],
                icon:data['Page'][i]['link_icon'],
                href:data['Page'][i]['url_content'],
                page_type:page_type,
                page_content:data['Page'][i],
              });
            }else if(page_type==='text'){
              //text pages link to slugs, and content is displayed within those slug-based paths
              menu_items.push({
                title:data['Page'][i]['name'],
                icon:data['Page'][i]['link_icon'],
                href:data['Page'][i]['slug']+refcode_suffix,
                slug:data['Page'][i]['slug'],
                page_type:page_type,
                page_content:data['Page'][i],
              });
            }else if(page_type==='form'){
              //form pages link to slugs, and content is displayed within those slug-based paths
              menu_items.push({
                title:data['Page'][i]['name'],
                icon:data['Page'][i]['link_icon'],
                href:data['Page'][i]['slug']+refcode_suffix,
                slug:data['Page'][i]['slug'],
                page_type:page_type,
                page_content:data['Page'][i],
              });
            }
          }

          menu_items.push({
            title:'Get Goos',
            icon:'address-card',
            href:'https://goos.app',
            page_type:'link',
            target:'_blank',
          });
          
          if((cmpnt.state.referral_code!==null) && (cmpnt.state.app_id!==null) && (cmpnt.state.app_id!==' tmp ')){
            console.log('Loading app for customer user '+data.User.app_id+' on subdomain '+cmpnt.state.app_id);
            if(data.User.app_id!==cmpnt.state.app_id){
              console.log('Warning: subdomain and user app id don\'t match!');
              //set this as an invalid referral code in this case
              //as the combination of app_id and domain is not valid
              cmpnt.setState({
                open_modals:[],
                customer_user_info:null,
                customer_user_id:-1,
                referral_code:null,
                app_id:null,
              });
              return;
            }
          }
          
          update_browser_state(cmpnt.state.app_id,'customer-user',data.User);
          cmpnt.setState({
            customer_user_id:data.User.user_id,
            customer_user_info:data.User,
            home_menu_items:menu_items,
            accent_color:data.User.accent_color,
          });
          
          let accent_color='#0D5494';
          if((cmpnt.state.customer_user_info!==null) && (cmpnt.state.customer_user_info.accent_color!==null) && (cmpnt.state.customer_user_info.accent_color!=='')){
            accent_color='#'+cmpnt.state.customer_user_info.accent_color;
          }
          let icon_url=cmpnt.state.customer_user_info.profile_photo_url;
          let icon_mime='';
          if(icon_url!==undefined){
            let icon_url_bare=url_without_get_data(icon_url);
            icon_mime='image/png';
            if(icon_url_bare.endsWith('.jpg') || icon_url_bare.endsWith('.jpeg')){
              icon_mime='image/jpeg';
            }else if(icon_url_bare.endsWith('.gif')){
              icon_mime='image/gif';
            }
          }else{
            icon_url='';
          }

          let manifest_obj = {
            "name": "My1Card Business Card for "+cmpnt.state.customer_user_info.full_name,
            "short_name": cmpnt.state.customer_user_info.full_name,
            "description": "Connect with "+cmpnt.state.customer_user_info.full_name,
            "start_url": "/"+refcode_suffix,
            "background_color": "#000000",
            "theme_color": accent_color,
            "display": "standalone",
            "icons": [{
              "src": icon_url,
              "sizes": "256x256",
              "type": icon_mime
            }]
          };
          let blob = new Blob([JSON.stringify(manifest_obj)], {type: 'application/json'});
          let manifest_url = URL.createObjectURL(blob);
          let manifest_elem=document.getElementById('manifest-placeholder');
          if(manifest_elem!==null){
            manifest_elem.setAttribute('href',manifest_url);
          }

          //Apple iOS only makes partial use of the manifest.json file
          //the icon and title are specified here (apple-touch-icon and page title respectively) for iOS
          let touch_icon_elem=document.getElementById('touch-icon-placeholder');
          if(touch_icon_elem!==null){
            touch_icon_elem.setAttribute('href',icon_url);
          }
          let app_title_elem=document.getElementById('app-title');
          if(app_title_elem!==null){
            app_title_elem.innerText=cmpnt.state.customer_user_info.full_name;
          }
        }
      }(this),
      function (error) {},
      ((document.abort_controller!==undefined)?this.abort_signal:null),
      function (cmpnt) {
        return function (response) {
          //in this no_data_callback, we set component state such that the "Loading" screen is no longer displayed
          //and an error message like "User Not Found" or "App ID invalid" is displayed instead
          cmpnt.setState({
            load_title:'User Not Found',
            load_text_content:'No user was found for the app_id '+cmpnt.state.app_id,
          });
        }
      }(this)
    );
  }

  fetchConsumerData() {
    if(this.state.consumer_user_id>=0){
      fetch_info('/user/'+this.state.consumer_user_id,
        function (cmpnt) {
          return function (data) {
            update_browser_state(cmpnt.state.app_id,'consumer-user',data.User);
            
            cmpnt.setState({
              consumer_user_info:data.User
            });
            
            //log that this app was opened by this consumer
            cmpnt.logOpen();
          }
        }(this),
        function (error) {},
        ((document.abort_controller!==undefined)?this.abort_signal:null)
      );
    }
  }

  logOpen() {
    //log every time the user opens the app by sending a request to the server
    if(this.state.customer_user_id>=0 && this.state.consumer_user_id>=0){
      send_info(
        '/user/'+this.state.consumer_user_id+'/activity/'+this.state.customer_user_id,
        JSON.stringify({
          activity_type:'app_open',
          activity_time:(new Date()).toUTCString(),
          user_id:this.state.customer_user_id,
          page_user_id:this.state.consumer_user_id
        }),
        function(data){}
      );
    }
  }

  componentDidMount(){
    //needed in order to cancel network requests on unmount
    if(document.abort_controller!==undefined){
      this.abort_controller = new document.abort_controller();
      this.abort_signal = this.abort_controller.signal;
    }
    const params = new URLSearchParams(window.location.search);
    let visited = get_browser_state_field(this.state.app_id,'visited');
    if(params.has('visited') || visited){
      update_browser_state(this.state.app_id,'visited',true);
    }else{
      appendQueryParam('visited','true');
    }
    let app_id=this.state.app_id;

    //highlight the shortcut menu appropriately based on our page location
    let shortcut_page_defs=[
      {
        slug:'news',
        highlight:'news',
      },
      {
        slug:'recommendations',
        highlight:'recommendations',
      },
      {
        slug:'profile',
        highlight:'profile',
      },
      {
        slug:'qr-code',
        highlight:'qrcode',
      },
      {
        slug:'network',
        highlight:'network',
      },
      {
        slug:'',
        highlight:'profile',
      },
      {
        slug:'calculator',
        highlight:'',
      },
      {
        slug:'request-showing',
        highlight:'',
      },
    ]
    for(var idx=0;idx<shortcut_page_defs.length;idx++){
      if(window.location.pathname===('/'+shortcut_page_defs[idx].slug)){
        if(this.state.selected_shortcut!==shortcut_page_defs[idx].highlight){
          this.setState({selected_shortcut:shortcut_page_defs[idx].highlight});
        }
        break;
      }
    }

    //if referral code is present then load based on that
    if(this.state.referral_code!==null){
      let error_or_no_data_callback=function (cmpnt) {
        return function (response) {
          cmpnt.setState({
            customer_user_id:-1,
            consumer_user_id:-1,
            
            referral_code_id:-1,
            referral_code:null,
            app_id:null,
          });
        };
      }(this);
      fetch_info('/referral/?code='+this.state.referral_code+'&Authorization='+this.state.authorization,
        function (cmpnt) {
          return function (data) {
            // if user is already registered then consumer_user_info is grabbed on app init
            cmpnt.setState({
              referral_code_id:data['Referral']['referral_code_id'],
              customer_user_id:data['Referral']['referred_to'],
            });
            cmpnt.fetchCustomerData();
            //if the user hasn't registered and therefore doesn't already have an account id
            //then get whatever data we can from the referral table
            if(cmpnt.state.consumer_user_id<0){
              send_info('/user/',JSON.stringify({
                full_name: data['Referral']['name'],
                phone:data['Referral']['phone_num'],
                email:data['Referral']['email']
              }),function(register_data){
                cmpnt.setState({
                  install_state:'registered',
                  consumer_user_info:register_data['User'],
                  consumer_user_id: register_data['User']['user_id']
                },()=>{
                  update_browser_state(cmpnt.state.app_id,'consumer-user',register_data.User);
                  update_browser_state(cmpnt.state.app_id,'install-state','registered');
                });
                send_info(
                  '/user/'+register_data.User.user_id+'/activity/'+cmpnt.state.customer_user_id,
                  JSON.stringify({
                    activity_type:'install',
                    activity_time:(new Date()).toUTCString(),
                    user_id:cmpnt.state.customer_user_id,
                    referral_code_id: data['Referral']['referral_code_id'],
                    page_user_id:register_data.User.user_id
                  }),
                  function(register_data){}
                );
              },function(res){console.log(res);},null,'POST');
            }

            //fetch the current consumer data (even if we already had it, it could have been updated)
            cmpnt.fetchConsumerData();
          }
        }(this),
        error_or_no_data_callback,
        ((document.abort_controller!==undefined)?this.abort_signal:null),
        error_or_no_data_callback,
      );
    //otherwise still attempt to load the data if we have an app_id to load from
    }else if(app_id!==null){
      //fetch based on app_id, instead of referral code
      this.fetchCustomerData(app_id);

      if(this.state.consumer_user_id>=0){
        this.fetchConsumerData();
      }else{
        //no referral code means no consumer user exists if they haven't registered
        //so ensure that those fields are intialized blank
        this.setState({
          consumer_user_id:-1,
          consumer_user_info:{
            name:'',
            email:'',
            cell_phone:'',
          },
        });
      }
    }else{
      console.log('Cannot load any data; state is as follows');
      console.log(this.state);
      
      this.setState({
        open_modals:[],
        customer_user_info:null,
        customer_user_id:-1,
        referral_code:null,
        app_id:null,
      });
    }
  }

  componentWillUnmount() {
    //stop AJAX requests
    if(document.abort_controller!==undefined){
      this.abort_controller.abort();
    }

    //do any other necessary cleanup here
  }

  toggle_modal(modal_id) {
    let open_modals=this.state.open_modals;
    if(this.state.open_modals.includes(modal_id)){
      open_modals=[];
      for(var i=0;i<this.state.open_modals.length;i++){
        if(this.state.open_modals[i]===modal_id){
          continue;
        }
        open_modals.push(this.state.open_modals[i]);
      }
    }else{
      open_modals.push(modal_id);
      window.scrollTo(0,0);
    }
    this.setState({open_modals:open_modals});
  }

  set_consumer_user_info(name,phone,email){
    let consumer_user_info=this.state.consumer_user_info;
    consumer_user_info.full_name=name;
    consumer_user_info.cell_phone=phone;
    consumer_user_info.email=email;
    
    this.setState({consumer_user_info:consumer_user_info});
  }

  show_share_output(content){
    this.setState({share_output:content});
  }
  
  render() {
    let inline_style='';
    let accent_color=this.state.accent_color;
    if((this.state.customer_user_info!==null) && (this.state.customer_user_info.accent_color!==null) && (this.state.customer_user_info.accent_color!=='')){
      accent_color='#'+this.state.customer_user_info.accent_color;
    }
    if(accent_color!==null && accent_color!==''){
      let tinycolor_accent=tinycolor(accent_color);
      let light_accent_color=tinycolor_accent.lighten(20).toString();
      let vlight_accent_color=light_accent_color;
      let dark_accent_color=tinycolor_accent.darken(10).toString();
      
      //NOTE: eslint doesn't like multi-line strings like this because it wasn't supported until ECMAscript 5
      //but everything we care about supports it so we can ignore warnings for that
      /* eslint-disable */
      inline_style=(
        <style>{'\
          a.hover-bg:hover, a.hover-bg:focus, a.hover-bg:active {\
            background-color:'+vlight_accent_color+';\
          }\
          \
          .page-title-cont {\
            background-color:'+accent_color+';\
            background-image:url("'+header_bg+'");\
          }\
          \
          a.button, input[type=submit] {\
            background-color:'+accent_color+';\
          }\
          a.button.transparent, input[type=submit].transparent {\
            color:'+accent_color+';\
            border:1px solid '+light_accent_color+';\
          }\
          a.button:hover, a.button:active, a.button:focus,\
          input[type=submit]:hover, input[type=submit]:focus, input[type=submit]:active {\
            background-color:'+dark_accent_color+';\
          }\
          \
          .input-cont.dollar-amount::before, .input-cont.percentage::before {\
            color:'+light_accent_color+';\
          }\
          .input-cont.yes-no-checkbox > input[type=checkbox] ~ .no .checkbox-icon {\
            background:radial-gradient('+accent_color+' 50%, transparent 60%);\
          }\
          .input-cont.yes-no-checkbox > input[type=checkbox]:checked ~ .yes .checkbox-icon {\
            background:radial-gradient('+accent_color+' 50%, transparent 60%);\
          }\
          \
          .shortcut-menu-cont .shortcut-menu .menu-entry {\
            color:'+accent_color+';\
          }\
          .shortcut-menu-cont .shortcut-menu .menu-entry.is-active {\
            border-top:4px solid '+accent_color+';\
          }\
          .shortcut-menu-cont .shortcut-menu .menu-entry:hover label.menu-entry-title, \
          .shortcut-menu-cont .shortcut-menu .menu-entry:focus label.menu-entry-title, \
          .shortcut-menu-cont .shortcut-menu .menu-entry:active label.menu-entry-title {\
            /*color:'+accent_color+';*/\
          }\
          \
          .list-menu .menu-entry .icon-cell {\
            color:'+accent_color+';\
          }\
          \
          .business-card-cont {\
            background-color:'+accent_color+';\
            background-image:url("'+header_bg+'");\
          }\
          .business-card-cont .business-card .realtor-info-cell .name {\
            /* color:'+accent_color+'; */\
          }\
          .business-card-cont .business-card .about-link-cell .about-link {\
            color:'+accent_color+';\
          }\
          \
          .calculator-result-display .totals-container {\
            /* border-top:2px solid '+light_accent_color+'; */\
          }\
          .calculator-result-display .totals-grid .total-amount {\
            /* color:'+light_accent_color+'; */\
          }\
          \
          .news .news-item-cell .news-title {\
            /* color:'+accent_color+'; */\
          }\
          \
          .request-showing .request-showing-section .section-title {\
            color:'+light_accent_color+';\
          }\
          \
          .login-page {\
            background-color:'+accent_color+';\
            background-image:url("'+header_bg+'");\
          }\
        '}</style>
      );
      /* eslint-enable */
    }else{
      //if not accent color is set, still set the background image
      
      //NOTE: eslint doesn't like multi-line strings like this because it wasn't supported until ECMAscript 5
      //but everything we care about supports it so we can ignore warnings for that
      /* eslint-disable */
      inline_style=(
        <style>{'\
          .page-title-cont {\
            background-image:url("'+header_bg+'");\
          }\
          .business-card-cont {\
            background-image:url("'+header_bg+'");\
          }\
          \
          .login-page {\
            background-image:url("'+header_bg+'");\
          }\
        '}</style>
      );
      /* eslint-enable */
    }
    
    var has_footer_logo=((this.state.customer_user_info!==null) && ('footer_logo_url' in this.state.customer_user_info) && (this.state.customer_user_info['footer_logo_url']!==null) && (this.state.customer_user_info['footer_logo_url']!==''));
    var cmpnt=this;
    let smsroute = null;
    let authorization=get_browser_state_field(cmpnt.state.app_id,'login-session');
    if(cmpnt.state.consumer_user_info!==null && cmpnt.state.customer_user_info!==null && cmpnt.state.consumer_user_info.email===cmpnt.state.customer_user_info.email && authorization!==null){
      smsroute = 
      <Route path="/sms-broadcast" exact={true} component={() => (<SMSBroadcastPage parentComponent={this} customer_user_info={this.state.customer_user_info} consumer_user_info={this.state.consumer_user_info} />)} />
    }

    let visited = get_browser_state_field(cmpnt.state.app_id,'visited');
    if(visited===null){
      visited=false;
    }
    return (
      <div className="App">
        {inline_style}
        {this.state.app_expired?
        <ExpiredApp/>
        :<React.Fragment>
        <Collapse isOpened={this.state.open_modals.includes('update-info')}>
          <UpdateInformation parentComponent={this} consumer_user_info={this.state.consumer_user_info} />
        </Collapse>
      
        <Collapse isOpened={this.state.open_modals.includes('share')}>
          <Share install_state={this.state.install_state} parentComponent={this} consumer_user_info={this.state.consumer_user_info} customer_user_info={this.state.customer_user_info} />
        </Collapse>

        <Modal parentComponent={this} content={this.state.share_output} show={this.state.share_output.length>0} id='share-success-reveal' />
        {this.state.open_modals.length===0?
          (this.state.customer_user_info===null?
            (((this.state.app_id===null) && (this.state.referral_code===null))?
              <ReferralCodeFailure parentComponent={this} referral_code={this.state.referral_code} />
              :
              <TextPage page_content={{name:this.state.load_title,text_content:this.state.load_text_content}} centered={true} />
            )
          :
            <div>
              <Router history={this.state.history}>
                <div>
                  <Route path="/" exact={true} component={() => (<ProfilePage app_id={this.state.app_id} parentComponent={this} consumer_user_info={this.state.consumer_user_info} customer_user_info={this.state.customer_user_info} home_menu_items={this.state.home_menu_items} install_state={this.state.install_state} />)} />
                  <Route path="/news" exact={true} component={() => (<NewsPage customer_user_info={this.state.customer_user_info} consumer_user_info={this.state.consumer_user_info}/>)} />
                  <Route path="/calculator" exact={true} component={MortgageCalculator} />
                  <Route path="/request-showing" exact={true} component={() => (<RequestShowing parentComponent={this} customer_user_info={this.state.customer_user_info} consumer_user_info={this.state.consumer_user_info} />)} />
                  <Route path="/network" exact={true} component={() => (<Recommendations parentComponent={this} customer_user_info={this.state.customer_user_info} consumer_user_info={this.state.consumer_user_info}/>)} />
                  <Route path="/register" exact={true} component={() => (<RegisterPage parentComponent={this} customer_user_info={this.state.customer_user_info} consumer_user_info={this.state.consumer_user_info} registration_output={this.state.registration_output} />)} />
                  <Route path="/contact-me" exact={true} component={() => (<ContactPage parentComponent={this} customer_user_info={this.state.customer_user_info} consumer_user_info={this.state.consumer_user_info} />)} />
                  <Route path="/social" exact={true} component={() => (<SocialPage parentComponent={this} customer_user_info={this.state.customer_user_info} />)} />
                  <Route path="/qr-code" exact={true} component={() => (<QRCodePage parentComponent={this} customer_user_info={this.state.customer_user_info} />)} />
                  <Route path="/login" exact={true} component={() => (<LoginPage parentComponent={this} customer_user_info={this.state.customer_user_info} consumer_user_info={this.state.consumer_user_info} />)} />
                  {smsroute}
                  {this.state.home_menu_items.map(function(menu_item) {
                    if(menu_item.page_type==='text'){
                      if(menu_item.slug === 'about-me') {
                        //log every time the user opens the app by sending a request to the server
                        if (cmpnt.state.customer_user_info.user_id >= 0 && cmpnt.state.consumer_user_info.user_id >= 0) {
                          send_info(
                            '/user/' + cmpnt.state.consumer_user_info.user_id + '/activity/' + cmpnt.state.customer_user_info.user_id,
                            JSON.stringify({
                              activity_type: 'about_me',
                              activity_time: (new Date()).toUTCString(),
                              user_id: cmpnt.state.customer_user_info.user_id,
                              page_user_id: cmpnt.state.consumer_user_info.user_id
                            }),
                            function (data) { }
                          );
                        }
                      }
                      return (
                        <Route key={'page-route-'+menu_item.page_content.slug} path={"/"+menu_item.slug} exact={true} component={() => (<TextPage parentComponent={cmpnt} page_content={menu_item.page_content} />)} />
                      );
                    }else if(menu_item.page_type==='form'){
                      return (
                        <Route key={'page-route-'+menu_item.page_content.slug} path={"/"+menu_item.slug} exact={true} component={() => (<CustomForm parentComponent={cmpnt} page_content={menu_item.page_content} consumer_user_info={cmpnt.state.consumer_user_info} customer_user_info={cmpnt.state.customer_user_info} install_state={cmpnt.state.install_state} />)} />
                      );
                    }

                    return null;
                  })}
                </div>
              </Router>
              {has_footer_logo?
                <img className='footer-logo' src={this.state.customer_user_info['footer_logo_url']} alt='Footer Logo' />
              :''}
            </div>
          )
        :''}
        
        {/* NOTE: we hide the shortcut menu for users who have not yet completed the install */}
        {visited?
          <ShortcutMenu selected={this.state.selected_shortcut} parentComponent={this} customer_user_info={this.state.customer_user_info} consumer_user_info={this.state.consumer_user_info} install_state={this.state.install_state} />
        :null}
        </React.Fragment>}
      </div>
    );
  }
}

export default App;
