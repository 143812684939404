import React from 'react';

import {send_info} from '../lib/libajax.jsx';
import {reformat_phone} from '../lib/libformat.jsx';

class Share extends React.Component {
  constructor(props) {
    super();
    
    this.state={
      share_status:'not-started',
      share_output:'',
    };
  }

  clear_share_form(){
    document.getElementById('share-to-name').value='';
    document.getElementById('share-to-phone').value='';
    document.getElementById('share-to-email').value='';
    
    this.setState({share_output:'',share_status:'not-started'});
  }
  
  share_form_submit(cmpnt,e) {
    e.preventDefault();
    
    let new_referral={
      referred_by_id:cmpnt.props.consumer_user_info.user_id,
      referred_to_id:cmpnt.props.customer_user_info.user_id,
      name:document.getElementById('share-to-name').value,
      phone:document.getElementById('share-to-phone').value,
      email:document.getElementById('share-to-email').value,
    };
    console.log(new_referral);
    cmpnt.setState({share_status:'pending',share_output:''});
    
    //send this data to the server and display the result (success/failure)
    send_info('/referral/',JSON.stringify(new_referral),
      function(data) {
        console.log(data);
        let share_output='You have successfully shared this app';
        cmpnt.setState({share_status:'complete',share_output:share_output});
//        window.scrollTo(0,document.getElementById('share-response-output').offsetTop);
	
	//NOTE: activity logging occurs in the handler at the /referral/ endpoint and so doesn't need to be explicitly done here
	
        //clear the form in case we want to share to someone else after this
        cmpnt.clear_share_form();
        
        //on success, just close the share dialog
        cmpnt.props.parentComponent.toggle_modal('share');
        cmpnt.props.parentComponent.show_share_output(share_output);
      },
      function(response){
        cmpnt.setState({share_status:'failure',share_output:'An error occurred and the app could not be shared at this time'});
        window.scrollTo(0,document.getElementById('share-response-output').offsetTop);
      },
      null,
      'POST');
    
    return false;
  }
  
  render () {

    if(this.props.install_state !== 'registered'){
      return(
        <div className='modal left-align share-display'>
        <div className='grid-container'>
          <div className='please-register'>
          You must register before sharing your app.
            <a href='/register'>
            Click here to register</a>
          </div>
        </div>
        </div>

      )
    }
    return (
      <div className='modal left-align share-display'>
        <div className='grid-container'>
          <div className='modal-close-cont'>
            <a className='modal-close'
              onClick={function (cmpnt) {
                return function(e) {
                  cmpnt.props.parentComponent.toggle_modal('share');
                }
              }(this)}
            >
              &times;
            </a>
          </div>
        </div>
        <div className='grid-container'>
          <div className='grid-x grid-padding-x'>
            <div className='small-12 cell'>
              <h2 className='modal-title'>Share App</h2>
            </div>
          </div>
          <form method='post' onSubmit={function(cmpnt) {
            return function (e) {
              return cmpnt.share_form_submit(cmpnt,e);
            };
          }(this)}>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell'>
                <label htmlFor='share-to-name'>Name of person receiving app*</label>
                <div className='input-cont'>
                  <input type='text' id='share-to-name' name='share-to-name' required
                    placeholder='...'
                  />
                </div>
              </div>
              <div className='small-12 cell'>
                <label htmlFor='share-to-phone'>What is their phone number?*</label>
                <div className='input-cont'>
                  <input type='text' id='share-to-phone' name='share-to-phone' pattern='(\([0-9]{3}\) [0-9]{3}-[0-9]{4})|([0-9]{10})'
                    required placeholder='(___) ___-____'
                    onKeyUp={function(e){
                      e.target.value=reformat_phone(e.target.value,true);
                    }}
                  />
                </div>
              </div>
              <div className='small-12 cell'>
                <label htmlFor='share-to-email'>What is their email? (optional)</label>
                <div className='input-cont'>
                  <input type='email' id='share-to-email' name='share-to-email'
                    placeholder='...'
                  />
                </div>
              </div>
              <div className='small-12 cell'>
                <input type='submit' value='Share App' />
              </div>
            </div>
          </form>
          <div className='grid-x grid-padding-x'>
            <div className='small-12 cell'>
              <div className={'response-output '+(this.state.share_status==='complete'?'success':(this.state.share_status==='failure'?'error':''))}  id='share-response-output'>{this.state.share_output}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Share;
