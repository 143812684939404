import React from 'react';

import BusinessCard from '../component/BusinessCard.jsx';
import ListMenu from '../component/ListMenu.jsx';
import InstallInstructions from '../component/InstallInstructions';

import { get_browser_state_field } from '../lib/libstorage.jsx';

class ProfilePage extends React.Component {
  constructor(props) {
    super();
    
    this.state={
    };
  }
  
  render() {
    var main_content='';
    let visited = get_browser_state_field(this.props.app_id,'visited');
    if(visited===null){
      visited=false;
    }
    switch(this.props.install_state){
      case 'registered':
      case 'unknown':
      case 'not_installed':
      case 'installed':
      default:
        main_content=(
          <div>
            {visited?
              <ListMenu
                list_slug='home-page-menu'
                customer_user_id={this.props.parentComponent.state.customer_user_id}
                parentComponent={this.props.parentComponent}
                menu_items={this.props.home_menu_items}
              />
            :
              <InstallInstructions app_id={this.props.app_id} app_installed={this.props.app_installed} primaryComponent={this.props.parentComponent} />
            }
            
          </div>
          
        );
        break;
    }
    return (
      <div>
        <BusinessCard
          customer_user_id={this.props.parentComponent.state.customer_user_id}
          customer_user_info={this.props.customer_user_info}
          parentComponent={this.props.parentComponent}
        />
        {main_content}
      </div>
    );
  }
}

export default ProfilePage;

