import React, { Component } from 'react';
//import {Collapse} from 'react-collapse';

class BusinessCard extends Component {
  constructor(props) {
    super();

    this.state={
      business_logo:null,
      portrait:null,
      full_name:'Full Name',
      job_title:'Job Title',
      phone_number:'555-555-5555',
      business_address:'',
      detail_open:false,
      detail_text:'',
      use_card_image:false,
    };
  }
  
  componentDidMount() {
    //needed in order to cancel network requests on unmount
    if(document.abort_controller!==undefined){
      this.abort_controller = new document.abort_controller();
      this.abort_signal = this.abort_controller.signal;
    }
  }

  componentWillUnmount() {
    //stop AJAX requests
    if(document.abort_controller!==undefined){
      this.abort_controller.abort();
    }

    //do any other necessary cleanup here
  }
  
  render() {
    let full_name=this.state.full_name;
    let job_title=this.state.job_title;
    let business_address=this.state.business_address;
    let phone_number=this.state.phone_number;
    let business_logo=this.state.business_logo;
    let portrait=this.state.portrait;
    let business_card_photo=null;
    let use_card_image=this.state.use_card_image;
//    let display_email='';
    
    if(this.props.customer_user_info!==null) {
      let customer_user_info=this.props.customer_user_info
//      console.log(customer_user_info);
      
      full_name=customer_user_info['full_name'];
      job_title=customer_user_info['title'];
      
      //for null values convert to empty string
      //because javascript for whatever reason considers (string)(null) to be 'null' instead of ''
      business_address=((customer_user_info['company']!==null)?customer_user_info['company']+'<br />':'')+((customer_user_info['office_address']!==null)?customer_user_info['office_address']+'<br />':'');
      phone_number=((customer_user_info['cell_phone']!==null)?'Cell# '+customer_user_info['cell_phone']+'<br />':'')+((customer_user_info['work_phone']!==null)?'Work# '+customer_user_info['work_phone']+'<br />':'');
      
      if(customer_user_info['company_logo_url']!==undefined){
        business_logo=customer_user_info['company_logo_url'];
      }
      if(customer_user_info['profile_photo_url']!==undefined){
        portrait=customer_user_info['profile_photo_url'];
      }
      if(customer_user_info['business_card_url']!==undefined){
        business_card_photo=customer_user_info['business_card_url'];
      }
      use_card_image=customer_user_info['use_card_image'];

/*
      display_email=this.props.customer_user_info.email;
      if(this.props.customer_user_info.hasOwnProperty('display_email') && this.props.customer_user_info.display_email!==null && this.props.customer_user_info.display_email!==''){
        display_email=this.props.customer_user_info.display_email;
      }
*/
    }
    
    let business_card_content=(
      <section className='business-card'>
        <div className='grid-x grid-padding-x'>
          {this.state.business_logo && 
            <div className='small-12 cell business-logo-cell'>
              <img className='business-logo' src={business_logo} alt='Business Logo' />
            </div>
          }
          <div className='small-5 cell portrait-cell'>
            {portrait!==null?
              <div className='has-bg portrait' style={{backgroundImage:'url("'+portrait+'")'}}></div>
              :''
            }
          </div>
          <div className='small-7 cell realtor-info-cell'>
            <h1 className='name'>{full_name}</h1>
            <p className='job-title'>{job_title}</p>
            <p className='phone-number' dangerouslySetInnerHTML={{__html:phone_number}} />
            {/* <p className='email' dangerouslySetInnerHTML={{__html:display_email}} /> */}
          </div>
          <div className='small-12 cell business-info-cell'>
            <p className='business-address' dangerouslySetInnerHTML={{__html:business_address}} />
          </div>
        </div>
      </section>
    );
    
    if(use_card_image){
      console.log(business_card_photo);
      business_card_content=(
        <div className='business-card full-image'>
          <img className='business-card-image' src={business_card_photo} alt={'Business card for '+full_name} />
        </div>
      );
    }
    
    return (
      <div className='business-card-cont'>
        <div className='grid-container'>
          {business_card_content}
        </div>
      </div>
    );
  }
}

export default BusinessCard;

