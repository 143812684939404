import React from 'react';

import ListMenu from '../component/ListMenu.jsx';

class SocialPage extends React.Component {
  constructor(props) {
    super(props);

    let share_menu_items=[];
    
    if(props.customer_user_info!==null){
      //get social links from the user info
      let social_platforms=['facebook','instagram','linkedin','twitter'];
      for(var i=0;i<social_platforms.length;i++){
        if(props.customer_user_info[social_platforms[i]+'_link']!==null){
          share_menu_items.push({
            title:social_platforms[i],
            icon:social_platforms[i],
            href:props.customer_user_info[social_platforms[i]+'_link'],
          });
        }
      }
    }

    this.state={
      share_menu_items:share_menu_items,
    };
  }
  
  render() {
    let share_menu_items=this.state.share_menu_items;
    
    return (
      <div className='text-page'>
        <div className='page-title-cont'>
          <div className='grid-container'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell page-title-cell'>
                <h1 className='page-title'>Social Media</h1>
              </div>
            </div>
          </div>
        </div>
        <ListMenu menu_items={share_menu_items} css_classes='social-media' icon_cell_classes='small-3' title_cell_classes='small-9' empty_msg='No social media accounts linked, check back soon for updates!' link_target='_blank' />
      </div>
    );
  }
}

export default SocialPage;

