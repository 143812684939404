import React from 'react';
import { Collapse } from 'react-collapse';

import MortgageCalculatorResult from '../component/MortgageCalculatorResult.jsx';

class MortgageCalculator extends React.Component {
  constructor(props) {
    super();

    let calc_result={
      loan_term_years:null,
      components:[],
    };


    this.state={
      page_title:'Mortgage Calculator',
      open_modal:null,
      calc_result:calc_result,
    };
  }

  show_result(loan_term_years,principal,principal_with_interest,total_taxes,total_home_ins,total_hoa) {
    let calc_result={
      loan_term_years:loan_term_years,
      loan_amount:principal,
      components:[
        {
          name:'Principal & Interest',
          value:principal_with_interest,
          color:'rgba(143,204,102,1)'
        },
        {
          name:'Taxes',
          value:total_taxes,
          color:'rgba(143,18,255,1)'
        },
        {
          name:'Home Owner Ins',
          value:total_home_ins,
          color:'rgba(255,168,41,1)'
        },
        {
          name:'HOA/Condo Dues',
          value:total_hoa,
          color:'rgba(227,28,28,1)'
        }
        //TODO: include MGIC-provided mortgage insurance rates based on the other parameters
      ]
    };
    
    //animate the chart
    document.getElementById('mortgage-breakdown-chart').className='breakdown-chart';
    
    this.setState({
      open_modal:'calculator-result',
      calc_result:calc_result,
    });
    window.scrollTo(0,0);
  }
  hide_result() {
    this.setState({open_modal:null});
    document.getElementById('mortgage-breakdown-chart').className='breakdown-chart collapsed';
  }

  //formats a number to be separated at every third digit
  //for example 1000000 would become the string "1,000,000"
  static pretty_print_number(num,group_len=3,separator=',',max_fractional_digits=0){
    //remove any non-numeric characters other than a literal decimal point
    let num_str=MortgageCalculator.clean_number(num)+'';
    
    //find the index of the least significant digit
    let lsd_idx=num_str.length-1;
    let dot_idx=num_str.indexOf('.');
    if(dot_idx>=0){
      lsd_idx=(dot_idx-1);
    }
    
    while(lsd_idx>=group_len){
      let separator_idx=(lsd_idx-group_len)+1;
      num_str=num_str.substring(0,separator_idx)+separator+num_str.substring(separator_idx);
      lsd_idx=separator_idx-1;
    }

    //if a maximum number of fractional digits was specified, then enforce that
    if(max_fractional_digits!==null){
      //NOTE: we have to re-search for the . character because the string changed
      //so the index from our previous search is no longer valid
      dot_idx=num_str.indexOf('.');
      if(dot_idx>=0){
        num_str=num_str.substring(0,dot_idx+1+max_fractional_digits);
        
        //trim off any trailing . characters
        while(num_str.charAt(num_str.length-1)==='.'){
          num_str=num_str.substring(0,num_str.length-1);
        }
      }
    }
    
    return num_str;
  }

  //cleans up (sanitizes) numbers for calculations
  static clean_number(num){
    //first remove any non-numeric characters other than the decimal point separator
    let num_str=((num+'').replace(/[^0-9.]/g,''));
    
    //if there is a decimal point, then remove any decimal points after the first one
    //only one decimal point character is allowed
    if(num_str.indexOf('.')>=0){
      //for each character after the first .
      for(var idx=num_str.indexOf('.')+1;idx<num_str.length;idx++){
        //if this character is itself a .
        if(num_str.charAt(idx)==='.'){
          //remove it
          num_str=num_str.substring(0,idx)+num_str.substring(idx+1);
          
          //decrease the index because the index we were at before no longer exists as we just removed that character
          //when this gets incremented on the next loop iteration it will be the same value as it was
          //but that will now be the next character in the string
          //as the string is one character shorter, having dropped the . character that was at the idx location previously
          idx--;
        }
      }
    }
    
    return num_str;
  }
  
  static total_with_compound_interest(principal,interest_rate,compounding_freq_per_year,loan_term_years){
    //the total cost with interest is given by the formula
    // P' = P*((1+r/n)^(n*t))
    // P' = Total with interest
    // P = principal (initial loan amount)
    // r = interest rate per compounding period (expressed as a fraction)
    // n = compounding frequency
    // t = total length of time for which this occurs (an integer number of compounding periods))
    return (principal)*Math.pow((1+(interest_rate/compounding_freq_per_year)),(loan_term_years*compounding_freq_per_year));
  }

  calculator_submit(cmpnt,e) {
    if(e!==null){
      e.preventDefault();
    }
    
    let purchase_price=document.getElementById('purchase-price').value;
    let down_perc=document.getElementById('down-payment-perc').value;
    
    //remove pretty formatting before doing calculations
    purchase_price=MortgageCalculator.clean_number(purchase_price);
    
    let down_payment=purchase_price*(down_perc/100.0);
    down_payment=MortgageCalculator.clean_number(down_payment);
    
    document.getElementById('down-payment-output').textContent='$'+MortgageCalculator.pretty_print_number(down_payment);
    
    //the principal is the amount of money that the loan is for, which is the total cost minus the down payment
    let principal=purchase_price-down_payment;
    let loan_term_years=document.getElementById('loan-term').value;
    let interest_rate=(document.getElementById('interest-rate-perc').value/100.0);
    let compounding_freq_per_year=document.getElementById('compounding-freq').value;
    
    //calculate the total based on the loan amount, time to repay, and compound interest
    let principal_with_interest = MortgageCalculator.total_with_compound_interest(principal,interest_rate,compounding_freq_per_year,loan_term_years);

    //add to the total the property taxes, insurance, and hoa/condo dues to present a more complete calculation
    let total_taxes=(MortgageCalculator.clean_number(document.getElementById('property-taxes').value)*loan_term_years);
    let total_home_ins=(MortgageCalculator.clean_number(document.getElementById('home-owner-ins').value)*loan_term_years);
    let total_hoa=(MortgageCalculator.clean_number(document.getElementById('hoa-condo-dues').value)*loan_term_years*12); //*12 because this input value is per-month instead of per-year
    
    let total_with_interest = principal_with_interest + total_taxes + total_home_ins + total_hoa;
    
    //this is the amount of money that must be paid per month to pay off the mortgage in the specified amount of time
    let monthly_payment=(total_with_interest)/(loan_term_years*12);
    
    document.getElementById('monthly-payment').value=MortgageCalculator.pretty_print_number(monthly_payment);
    
    cmpnt.show_result(loan_term_years,principal,principal_with_interest,total_taxes,total_home_ins,total_hoa);
    
    return false;
  }

  dollar_change_handler(elem_id) {
    return function (e) {
      let price_elem=document.getElementById(elem_id);
      price_elem.value=MortgageCalculator.pretty_print_number(price_elem.value);
    }
  }

  render() {
    let dollar_amount_pattern='([0-9]*|(([0-9]{1,3})(,[0-9]{3})*))\\.?([0-9]{0,2})';
    return (
      <section className='mortgage-calculator'>
        <Collapse isOpened={this.state.open_modal==='calculator-result'}>
          <MortgageCalculatorResult parentComponent={this} pretty_print_number={MortgageCalculator.pretty_print_number} calc_result={this.state.calc_result} />
        </Collapse>
        <div className='page-title-cont'>
          <div className='grid-container'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell page-title-cell'>
                <h1 className='page-title'>{this.state.page_title}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className='grid-container'>
          <form className='calculator-form' id='mortgage-calculator-form' onSubmit={function(cmpnt) {
            return function (e) {
              return cmpnt.calculator_submit(cmpnt,e);
            };
          }(this)}>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell'>
                <label htmlFor='purchase-price'>Purchase price</label>
                <div className='input-cont dollar-amount purchase-price'>
                  <input name='purchase-price' id='purchase-price' type='text' required pattern={dollar_amount_pattern} defaultValue={'300,000'}
                    onInput={this.dollar_change_handler('purchase-price')}
                  />
                </div>
              </div>
              <div className='small-6 cell left-cell'>
                <label htmlFor='down-payment-perc'>Down Payment</label>
                <div className='input-cont percentage down-payment-perc'>
                  <input name='down-payment-perc' id='down-payment-perc' type='number' min='0' step={0.1} required defaultValue='20' />
                </div>
              </div>
              <div className='small-6 cell calc-output-cell right-cell'>
                <div id='down-payment-output' />
              </div>
              <div className='small-6 cell left-cell'>
                <label htmlFor='interest-rate-perc'>Interest Rate</label>
                <div className='input-cont percentage interest-rate-perc'>
                  <input name='interest-rate-perc' id='interest-rate-perc' type='number' min='0' step={0.01} required defaultValue='1.5' />
                </div>
              </div>
              {/* NOTE: somewhere in here we need an input for compounding frequency since it could be different and that affects the monthly payment; this can be hidden if we want to default it */}
              <div className='small-6 cell right-cell'>
                <label htmlFor='compounding-freq'>Compounding Frequency</label>
                <div className='input-cont compounding-freq'>
                  <select id='compounding-freq' name='compounding-freq' required defaultValue={12}>
                    <option value={1}>Yearly</option>
                    <option value={12}>Monthly</option>
                    <option value={52.25}>Weekly</option>
                  </select>
                </div>
              </div>
              <div className='small-12 cell'>
                <label htmlFor='loan-term'>Loan Term</label>
                <div className='input-cont loan-term'>
                  <select id='loan-term' name='loan-term' required defaultValue={30}>
                    <option value={30}>30 Yr</option>
                    <option value={25}>25 Yr</option>
                    <option value={20}>20 Yr</option>
                    <option value={15}>15 Yr</option>
                    <option value={10}>10 Yr</option>
                    <option value={5}>5 Yr</option>
                  </select>
                </div>
              </div>
              <div className='small-6 cell left-cell'>
                <label htmlFor='property-taxes'>Property Taxes (/yr)</label>
                <div className='input-cont dollar-amount property-taxes'>
                  <input name='property-taxes' id='property-taxes' type='text' required pattern={dollar_amount_pattern} defaultValue='2,000'
                    onInput={this.dollar_change_handler('property-taxes')}
                  />
                </div>
              </div>
              <div className='small-6 cell right-cell'>
                <label htmlFor='home-owner-ins'>Home Owner Ins. (/yr)</label>
                <div className='input-cont dollar-amount home-owner-ins'>
                  <input name='home-owner-ins' id='home-owner-ins' type='text' required pattern={dollar_amount_pattern} defaultValue='700'
                    onInput={this.dollar_change_handler('home-owner-ins')}
                  />
                </div>
              </div>
              <div className='small-12 cell'>
                <label htmlFor='hoa-condo-dues'>HOA/Condo Dues (/mo)</label>
                <div className='input-cont dollar-amount hoa-condo-dues'>
                  <input name='hoa-condo-dues' id='hoa-condo-dues' type='text' required pattern={dollar_amount_pattern} defaultValue='0'
                    onInput={this.dollar_change_handler('hoa-condo-dues')}
                  />
                </div>
              </div>
              <div className='small-12 cell'>
                <label htmlFor='credit-score'>Credit Score</label>
                <div className='input-cont credit-score'>
                  <input name='credit-score' id='credit-score' type='number' min='0' defaultValue='720' />
                </div>
              </div>
              <div className='small-12 cell hide'>
                <label htmlFor='monthly-payment'>Monthly Payment</label>
                <div className='input-cont dollar-amount'>
                  <input name='monthly-payment' id='monthly-payment' type='text' pattern={dollar_amount_pattern} disabled />
                </div>
              </div>
              <div className='small-12 cell'>
                <label htmlFor='first-time-buyer'>First-time buyer?</label>
                <div className='input-cont yes-no-checkbox'>
                  <input type='checkbox' name='first-time-buyer' id='first-time-buyer' />
                  <label htmlFor='first-time-buyer' className='yn-option yes'>
                    <div className='checkbox-icon circle' />
                    <span className='text-label'>Yes</span>
                  </label>
                  <label htmlFor='first-time-buyer' className='yn-option no'>
                    <div className='checkbox-icon circle' />
                    <span className='text-label'>No</span>
                  </label>
                </div>
              </div>
              <div className='small-12 cell'>
                <input type='submit' value="Calculate" />
              </div>
            </div>
          </form>
        </div>
        <div className='section-separator'></div>
        <footer className='mortgage-calculator-terms'>
          <div className='grid-container'>
            <div className='terms-button-list'>
              <a className='button transparent'>View Current Rates</a>
              <a className='button transparent'>See a Property</a>
            </div>
            <p>
              {'This is not a commitment to lend. Not all borrowers will qualify. This is for illustrative purposes only and estimates are based on information you provided. Interest rates, mortgage insurance rates, and programs are subject to credit approbal and available rates and terms will vary, sometimes drastically, based on borrower eligibility and program selected. For accurate interest rates and mortgage insurance rates please contact your sales consultant. For illustrative purposes FHA/VA and USDA loan ammounts will include an estimate for the financed upfront MI amount.'}
            </p>
          </div>
        </footer>
      </section>
    );
  }
}

export default MortgageCalculator;

