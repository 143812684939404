import React from 'react';

class Modal extends React.Component {
  render() {
    return (
      <div className={'modal-overlay'+((this.props.show===false)?' hide':'')} id={this.props.id}
        onClick={
          function (cmpnt) {
            return function(e) {
              cmpnt.props.parentComponent.show_share_output('');
            }
          }(this)
        }
      >
        <div className='grid-container'>
          <div className='grid-x grid-padding-x'>
            <div className='small-12 cell'>
              <div className='modal-content'>
                <p>{this.props.content}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;

