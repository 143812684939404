import React from 'react';

import {send_info} from '../lib/libajax.jsx';
import {reformat_phone} from '../lib/libformat.jsx';
import { register } from '../helper-functions';

class CustomForm extends React.Component {
  constructor(props) {
    super();

    this.state={
      page_title:props.page_content.name,
      customer_name:props.parentComponent.state.customer_user_info.full_name,
      submission_state:'not-started',
      submission_output:'',
      form_fields:JSON.parse(props.page_content.text_content),
    };
  }

  custom_form_submit(cmpnt,e) {
    e.preventDefault();
    
    //if another submission is already in progress but hasn't completed
    if(cmpnt.state.submission_state==='pending'){
      //then don't do anything until that finishes
      //to prevent duplications and race conditions
      return false;
    }
    
    cmpnt.setState({
      submission_state:'pending',
      submission_output:'',
    });
    
    //get the user's data (that they may have just updated)
    let form_data={
      name:cmpnt.props.consumer_user_info.full_name,
      phone:cmpnt.props.consumer_user_info.cell_phone,
      email:cmpnt.props.consumer_user_info.email,
    };
    
    //get all the custom form fields out of the custom form
    let form_elem=document.getElementById('custom-form');
    let input_fields=Array.from(form_elem.getElementsByTagName('INPUT'));
    input_fields=input_fields.concat(Array.from(form_elem.getElementsByTagName('SELECT')));
    for(let i=0;i<input_fields.length;i++){
      if((input_fields[i].id!==undefined) && (input_fields[i].id!=='')){
        if((input_fields[i].tagName==='INPUT') && ((input_fields[i].type==='checkbox') || (input_fields[i].type==='radio'))){
          if(input_fields[i].checked){
            form_data[input_fields[i].id]=input_fields[i].value;
          }
        }else{
          form_data[input_fields[i].id]=input_fields[i].value;
        }
      }
    }
    form_data['full_name']=form_data['name'];
    register(form_data,cmpnt);
    let submission_data={
      user_id:cmpnt.props.customer_user_info.user_id,
      form_data:JSON.stringify(form_data)
    };

    //send this form submission to the server as json
    console.log(submission_data);
    
    let success_callback = function(data) {
      cmpnt.setState({
        submission_state:'complete',
        submission_output:'Success.  Your submission has been received.  ',
      });
      window.scrollTo(0,document.getElementById('form-response-output').offsetTop);
    };
    
    let error_callback = function(response){
      cmpnt.setState({
        submission_state:'failure',
        submission_output:'Error: your submission could not be processed at this time',
      });
      window.scrollTo(0,document.getElementById('form-response-output').offsetTop);
    };
    
    send_info('/page/'+this.props.page_content.page_id+'/form_entry',
      JSON.stringify(submission_data),
      success_callback,
      error_callback,
      null,
      'POST',
      error_callback
    );
    
    return false;
  }

  render() {
    if(this.props.install_state !== 'registered'){
      return(
        <div className='modal left-align share-display'>
          <div className='grid-container'>
            <div className='please-register'>
              You must register before filling out forms.
              <a href='/register'>Click here to register</a>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className='custom-form-page'>
        <div className='page-title-cont'>
          <div className='grid-container'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell page-title-cell'>
                <h1 className='page-title'>{this.state.page_title}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className='grid-container'>
          <section className='custom-form-section'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell'>
                <h3 className='section-title'>1. Review Your Information</h3>
              </div>
            </div>
            <div className='info-section'>
              <div className='label-cell'>
                Name:
              </div>
              <div className='content-cell'>
                {this.props.consumer_user_info.full_name}
              </div>
            </div>
            <div className='info-section'>
              <div className='label-cell'>
                Phone:
              </div>
              <div className='content-cell'>
                {reformat_phone(this.props.consumer_user_info.cell_phone)}
              </div>
            </div>
            <div className='info-section'>
              <div className='label-cell'>
                Email:
              </div>
              <div className='content-cell'>
                {this.props.consumer_user_info.email}
              </div>
            </div>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell'>
                <a className='button transparent update-info-button'
                  onClick={function (cmpnt) {
                    return function (e) {
                      cmpnt.props.parentComponent.toggle_modal('update-info');
                    };
                  }(this)}
                >
                  Update My Information
                </a>
              </div>
            </div>
          </section>
        </div>
        <div className='section-separator'></div>
        <div className='grid-container'>
          <section className='request-showing-section'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell'>
                <h3 className='section-title'>2. Fill Out The Form</h3>
              </div>
            </div>
            <form id='custom-form' className='custom-form' method='post'
              onSubmit={function(cmpnt) {
                return function (e) {
                  return cmpnt.custom_form_submit(cmpnt,e);
                };
              }(this)}
            >
              <div className='grid-x grid-padding-x'>
                {this.state.form_fields.map(function (field) {
                  let field_html='';
                  let show_label=true;
                  switch(field.type){
                    //unsupported input types
                    case 'button':
                    case 'header':
                    case 'paragraph':
                    case 'autocomplete':
                    case 'file':
                      return '';
                      //eslint-disable-next-line
                      break;

                    case 'radio-group':
                    case 'checkbox-group':
                      let field_type=(field.type==='radio-group')?'radio':'checkbox';
                      show_label=false;
                      field_html=(
                        <fieldset>
                          {field.values.map(function (option_value) {
                            return (
                              <div className='input-cont x-checkbox' key={field.name+'-'+option_value.value}>
                                <input type={field_type} name={field.name} id={field.name+'-'+option_value.value} value={option_value.value} defaultChecked={option_value.selected} />
                                <label className='x-checkbox-label' htmlFor={field.name+'-'+option_value.value}><div className={'checkbox-icon'+(field_type==='radio'?' rounded ':'')} />{option_value.label}</label>
                              </div>
                            );
                          })}
                        </fieldset>
                      );
                      break;
                    
                    //selection
                    case 'select':
                      field_html=(
                        <select id={field.name} name={field.name} required={field.required}>
                          {field.values.map(function (option_value) {
                            return <option key={'field-option-'+field.name+'-'+option_value.value} value={option_value.value}>{option_value.label}</option>
                          })}
                        </select>
                      )
                      break;
                    
                    //textarea input
                    case 'textarea':
                      field_html=(
                        <div className='input-cont'>
                          <textarea id={field.name} name={field.name} placeholder='...' required={field.required}></textarea>
                        </div>
                      );
                      break;
                    
                    //text input, date input, number input
                    case 'text':
                    case 'date':
                    case 'number':
                    case 'hidden':
                    default:
                      field_html=(
                        <div className='input-cont'>
                          <input type={field.type} id={field.name} name={field.name} placeholder='...' required={field.required} />
                        </div>
                      );
                      break;
                  }
                  return (
                    <div key={'form-field-'+field.name} className='small-12 cell'>
                      {show_label?<label htmlFor={field.name}>{field.label}</label>:''}
                      {field_html}
                    </div>
                  );
                })}
                <div className='small-12 cell'>
                  <input type='submit' value='Submit Form' />
                </div>
              </div>
            </form>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell'>
                <div className={'response-output '+(this.state.submission_state==='complete'?'success':(this.state.submission_state==='failure'?'error':''))}  id='form-response-output'>{this.state.submission_output}</div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default CustomForm;

