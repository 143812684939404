import React from 'react';

export default class ExpiredApp extends React.Component {
	render() {
		return (
			<div className="text-page">
				<div className="page-title-cont">
					<div className="grid-container">
						<div className="grid-x grid-padding-x">
							<div className="small-12 cell page-title-cell">
								<h1 className="page-title">
									Expired Application
								</h1>
								<p className="expired-text">
									Please contact the owner for more
									information
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
