import config from '../config.js';

//reformat a phone number in any format to match the phone number format we have defined
export function reformat_phone(phone_number,allow_partial=false){
  //first remove any non-numeric characters
  phone_number=phone_number.replace(/\D/g,'');
  
  //if this has the correct number of digits then format it as expected
  if(phone_number.length===10){
    return '('+phone_number.substring(0,3)+') '+phone_number.substring(3,6)+'-'+phone_number.substring(6,10);
  }
  
  //if we're doing partial formatting, then format whatever we have
  if(allow_partial){
    let phone_number_str=phone_number.substring(0,3)
    if(phone_number.length>3){
      phone_number_str='('+phone_number_str+') ';
      
      phone_number_str+=phone_number.substring(3,6)
      if(phone_number.length>6){
        phone_number_str+='-';
        
        phone_number_str+=phone_number.substring(6,10);
      }
    }
    return phone_number_str;
  }
  
  //if it doesn't have the right number of digits then just display it as numbers with no separators
  return phone_number;
}

export function phone_href(phone_number,link_type='tel'){
  //first remove any non-numeric characters
  phone_number=phone_number.replace(/\D/g,'');
  
  //add the international prefix (+1 for the US and Canada)
  if(phone_number.length===10){
    phone_number='1'+phone_number;
  }
  
  //use the tel: href property to create a link that phones will understand
  //if the given link_type is sms then this will be understood as a text message link
  phone_number=link_type+':+'+phone_number;
  
  return phone_number;
}

export function email_href(email_address,subject='',body=''){
  return 'mailto:'+email_address+'?subject='+subject+'&body='+body;
}

//get the app url for a given customer info object
export function get_customer_app_url(customer_app_id){
  let backend_url=config.backend_host;
  let url_parts=backend_url.split('://');
  let post_subdomain_url='';
  //if a protocol was specified in the backend host config then respect that
  if(url_parts.length>1){
    for(var i=1;i<url_parts.length;i++){
      post_subdomain_url+=url_parts[i];
    }
    
    //if the backend host is on a different port than this one
    //then change it to be on the same port for the front end
    let host_w_port=post_subdomain_url.split(':');
    if(host_w_port.length>1){
      post_subdomain_url=host_w_port[0]+':'+window.location.port;
      if(backend_url.endsWith('/')){
        post_subdomain_url+='/';
      }
    }
    
    //if we can use subdomains, then use subdomains
    if((url_parts[1].indexOf('my1card')===0)){
      return url_parts[0]+'://'+customer_app_id+'.'+post_subdomain_url;
    }else if(url_parts[1].indexOf('api.')===0){
      return url_parts[0]+'://'+customer_app_id+'.'+(post_subdomain_url.substring('api.'.length,post_subdomain_url.length));
    }
    
    //otherwise use app_id as GET data
    return url_parts[0]+'://'+post_subdomain_url+'?app_id='+customer_app_id;
  }else{
    //if the backend host is on a different port than this one
    //then change it to be on the same port
    let host_w_port=backend_url.split(':');
    if(host_w_port.length>1){
      post_subdomain_url=host_w_port[0]+':'+window.location.port;
      if(backend_url.endsWith('/')){
        post_subdomain_url+='/';
      }
    }
  }
  //no protocol was specified in the backend host config, so use the protocol the frontend is using
  return window.location.protocol+'//'+post_subdomain_url+'?app_id='+customer_app_id;
}

export function onclick_link(loc){
	return function (e) {
		e.preventDefault();
		window.location=loc;
		return false;
	};
}

export function phone_onclick(phone_number,link_type='tel'){
	return onclick_link(phone_href(phone_number,link_type));
}

export function email_onclick(email_address,subject='',body=''){
	return onclick_link(email_href(email_address,subject,body));
}

