import React from 'react';

import {phone_onclick, email_onclick} from '../lib/libformat.jsx';
import { send_info } from '../lib/libajax.jsx';

class ContactPage extends React.Component {
  constructor(props) {
    super();

    this.state={
    };
  }

  componentDidMount() {
    //log every time the user opens the app by sending a request to the server
    if (this.props.customer_user_info.user_id >= 0 && this.props.consumer_user_info.user_id >= 0) {
      send_info(
        '/user/' + this.props.consumer_user_info.user_id + '/activity/' + this.props.customer_user_info.user_id,
        JSON.stringify({
          activity_type: 'contact_me',
          activity_time: (new Date()).toUTCString(),
          user_id: this.props.customer_user_info.user_id,
          page_user_id: this.props.consumer_user_info.user_id
        }),
        function (data) { }
      );
    }
  }

  render() {
    let customer_email=this.props.customer_user_info.email;
    if(this.props.customer_user_info.hasOwnProperty('display_email') && this.props.customer_user_info.display_email!==null && this.props.customer_user_info.display_email!==''){
      customer_email=this.props.customer_user_info.display_email;
    }
    
    return (
      <div className='contact-page'>
        <div className='page-title-cont'>
          <div className='grid-container'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell page-title-cell'>
                <h1 className='page-title'>Contact {this.props.customer_user_info.full_name===null?'Me':this.props.customer_user_info.full_name}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className='grid-container'>
          <div className='grid-x'>
            <div className='small-12 cell contact-page-cell'>
              {this.props.customer_user_info.cell_phone!==null?
		//eslint-disable-next-line
                <a className='button' href='#' onClick={phone_onclick(this.props.customer_user_info.cell_phone)}>
                  <div className='grid-x grid-padding-x'>
                    <div className='small-4 cell icon-cell'>
                      <i className='fa fa-phone' />
                    </div>
                    <div className='small-8 cell title-cell'>
                      Call Cell
                    </div>
                  </div>
                </a>
                :''
              }
              {this.props.customer_user_info.cell_phone!==null?
		//eslint-disable-next-line
                <a className='button' href='#' onClick={phone_onclick(this.props.customer_user_info.cell_phone,'sms')}>
                  <div className='grid-x grid-padding-x'>
                    <div className='small-4 cell icon-cell'>
                      <i className='fa fa-comment-dots' />
                    </div>
                    <div className='small-8 cell title-cell'>
                      Text Me
                    </div>
                  </div>
                </a>
                :''
              }
              {this.props.customer_user_info.email!==null?
		//eslint-disable-next-line
                <a className='button' href='#' onClick={email_onclick(customer_email,'My1Card Inquiry')}>
                  <div className='grid-x grid-padding-x'>
                    <div className='small-4 cell icon-cell'>
                      <i className='fa fa-envelope' />
                    </div>
                    <div className='small-8 cell title-cell'>
                      Email Me
                    </div>
                  </div>
                </a>
                :''
              }
              {this.props.customer_user_info.work_phone!==null?
		//eslint-disable-next-line
                <a className='button' href='#' onClick={phone_onclick(this.props.customer_user_info.work_phone)}>
                  <div className='grid-x grid-padding-x'>
                    <div className='small-4 cell icon-cell'>
                      <i className='fa fa-phone' />
                    </div>
                    <div className='small-8 cell title-cell'>
                      Call Office
                    </div>
                  </div>
                </a>
                :''
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactPage;

