import React from 'react';

class TextPage extends React.Component {
  constructor(props) {
    super();

    this.state={
    };
  }

  format_content(content_str){
    if((content_str===undefined) || (content_str===null)){
      return '';
    }
    
    let lines=content_str.split("\n");
    
    let formatted_str='';
    for(var n=0;n<lines.length;n++){
      formatted_str+='<p>'+lines[n]+'</p>';
    }
    return formatted_str;
  }

  render() {
    let centered=this.props.centered===true;
    return (
      <div className='text-page'>
        <div className='page-title-cont'>
          <div className='grid-container'>
            <div className='grid-x grid-padding-x'>
              <div className='small-12 cell page-title-cell'>
                <h1 className='page-title'>{this.props.page_content['name']}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className='grid-container nopadding'>
          <div className='grid-x'>
            <div className='small-12 cell text-page-cell'>
              <div className={'text-page-content'+(centered?' centered':'')} dangerouslySetInnerHTML={{__html:this.format_content(this.props.page_content['text_content'])}} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TextPage;

